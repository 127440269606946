import React, { useContext, useEffect, useState } from "react";
import {
  PopupParameter,
  BURoleConfig,
  InputFileMetaData,
} from "../Interface/Interface";
import Table from "../Table/Table";
import {
  defaultPopupParameter,
  dashboardTabName,
  adminTabName,
  userSuffix,
  selfServiceTabName,
} from "./../Constants/Constants";
import {
  CommonFlagConfigContext,
  CommonLogsDataConfigContext,
  CoreUserConfigContext,
  FileMetadataContext,
} from "../ContextAPI/ContextAPI";
import {
  fetchAdminUserRoleData,
  getMarketList,
  loginUser,
} from "../CommonFunctions/CommonFunctions";
import ManualUpload from "../ManualUpload/ManualUpload";
import Popup from "../Popup/Popup";
import RequestForm from "../RequestForm/RequestForm";
import Admin from "../Admin/Admin";
import SelfService from "../SelfService/SelfService";
import "./Home.css";

function generateTabClassName(selectedTab: string, name: string) {
  let tabClassName = "";
  if (selectedTab === name) {
    tabClassName = "selected-tab";
  }
  return tabClassName;
}

function generatePageName(isCoreUser: boolean): string {
  return isCoreUser ? "Manual Upload" : "Request Access";
}

function generateManualMarketTabClass(count: number): string {
  return count > 0 ? "" : "disabled-tab";
}

export function Home() {
  const coreUserConfig = useContext(CoreUserConfigContext);
  const commonFlags = useContext(CommonFlagConfigContext);
  const logsDataConfigs = useContext(CommonLogsDataConfigContext);
  const [popupParameters, setPopupParameters] = useState<PopupParameter>(
    defaultPopupParameter
  );

  const [selectedTab, setSelectedTab] = useState(dashboardTabName);
  const [allMarketList, setAllMarketList] = useState<string[]>([]);
  const [manualMarketList, setManualMarketList] = useState<string[]>([]);
  const [fileMetaData, setFileMetaData] = useState<InputFileMetaData[]>([]);
  const [isDataUpdateFlag, setIsDataUpdateFlag] = useState(false);
  const userName =
    coreUserConfig.config.userName != undefined
      ? coreUserConfig.config.userName &&
        loginUser(coreUserConfig.config.userName.replace(userSuffix, ""))
      : "";
  useEffect(() => {
    if (coreUserConfig.config.token !== "") {
      fetchAdminUserRoleData(coreUserConfig.config.userEmail).then((data) => {
        setIsDataUpdateFlag(true);
        const coreUserRole = data.userRoleinformation.buRoles.filter(
          (data: BURoleConfig) => {
            return data.roleType === "CORE_USER";
          }
        );
        if (coreUserRole.length > 0) {
          let buNames: string[] = coreUserRole.map(
            (value: BURoleConfig) => value.buName
          );
          coreUserConfig.setConfig({
            ...coreUserConfig.config,
            isCoreUser: true,
            BU: buNames,
            isAdmin: data.isAdmin,
          });
        } else {
          coreUserConfig.setConfig({
            ...coreUserConfig.config,
            isAdmin: data.isAdmin,
          });
        }
        commonFlags.setFlags({
          ...commonFlags.flags,
          isRefreshPageIcon: false,
        });
      });
      getMarketList().then((data) => {
        setAllMarketList(data.marketValue);
        setManualMarketList(data.manualMarketValue);
      });
    }
  }, [coreUserConfig.config.token]);

  useEffect(() => {
    if (
      commonFlags.flags.isShowUploadHistory &&
      (selectedTab !== logsDataConfigs.data.tabName ||
        logsDataConfigs.data.processLogMetadata.length === 0)
    ) {
      commonFlags.setFlags({
        ...commonFlags.flags,
        isShowUploadHistory: false,
      });
    }
  }, [selectedTab, logsDataConfigs.data, commonFlags.flags]);

  return (
    <>
      <div
        className={
          "home-section " +
          (coreUserConfig.config.isCoreUser || selectedTab === adminTabName ? "" : "request-access")
        }
      >
        {isDataUpdateFlag && (
          <>
            {(coreUserConfig.config.isCoreUser ||
              coreUserConfig.config.isAdmin) && (
              <>
                <div className="tab-section">
                  <div
                    className={
                      "nav-tab " +
                      generateTabClassName(selectedTab, dashboardTabName)
                    }
                    onClick={() => {
                      setSelectedTab(dashboardTabName);
                      logsDataConfigs.setData({
                        ...logsDataConfigs.data,
                        tabName: dashboardTabName,
                      });
                      commonFlags.setFlags({
                        ...commonFlags.flags,
                        isShowUploadHistory: false,
                      });
                    }}
                  >
                    {generatePageName(coreUserConfig.config.isCoreUser)}
                  </div>
                  {coreUserConfig.config.isCoreUser && (
                    <div
                      className={
                        "nav-tab " +
                        generateTabClassName(selectedTab, selfServiceTabName) +
                        generateManualMarketTabClass(fileMetaData.length)
                      }
                      onClick={() => {
                        if (fileMetaData.length > 0) {
                          setSelectedTab(selfServiceTabName);
                          logsDataConfigs.setData({
                            ...logsDataConfigs.data,
                            tabName: selfServiceTabName,
                          });
                          commonFlags.setFlags({
                            ...commonFlags.flags,
                            isShowUploadHistory: false,
                          });
                        }
                      }}
                    >
                      Manual Market
                    </div>
                  )}
                  {coreUserConfig.config.isAdmin && (
                    <div
                      className={
                        "nav-tab " +
                        generateTabClassName(selectedTab, adminTabName)
                      }
                      onClick={() => {
                        commonFlags.setFlags({
                          ...commonFlags.flags,
                          isShowUploadHistory: false,
                        });
                        setSelectedTab(adminTabName);
                      }}
                    >
                      Admin Portal
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="home-title">
              <label className="user-name">Welcome {userName},</label>
              <br />
              <>
                <FileMetadataContext.Provider
                  value={{
                    data: fileMetaData,
                    setData: setFileMetaData,
                  }}
                >
                  {selectedTab === adminTabName ? (
                    <Admin
                      popupParameters={popupParameters}
                      setPopupParameters={setPopupParameters}
                    />
                  ) : selectedTab === selfServiceTabName ? (
                    <SelfService allMarketList={manualMarketList} />
                  ) : (
                    <>
                      {coreUserConfig.config.isCoreUser ? (
                        <ManualUpload
                          allMarketList={allMarketList}
                          popupParameters={popupParameters}
                          setPopupParameters={setPopupParameters}
                        />
                      ) : (
                        <RequestForm allMarketList={allMarketList} />
                      )}
                    </>
                  )}
                </FileMetadataContext.Provider>
              </>
            </div>
            {commonFlags.flags.isShowUploadHistory && (
              <div className="table_segment">
                <div className="table-section">
                  <Table />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {commonFlags.flags.isRefreshPageIcon && (
        <div className="loader">
          <div className="loader-icon outer-loader"></div>
          <div className="loader-icon inner-loader"></div>
        </div>
      )}
      {popupParameters.isOpen && (
        <Popup
          popupParameters={popupParameters}
          setPopupParameters={setPopupParameters}
        />
      )}
    </>
  );
}
export default Home;
