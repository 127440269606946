import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import axios, { AxiosRequestConfig } from "axios";
import {
  FileMetaDataConfiguration,
  InputFileMetaData,
  PopupParameter,
  DropdownConfigurations,
  GraphqlQueryParamater,
  LogTableFilterConfigurations,
  InputFileProcessLogMetaData,
} from "../Interface/Interface";
import {
  graphqlAPIURL,
  inputFileMetadataQuery,
  uploadExcelFileURL,
  defaultPopupParameter,
  timePeriodValues,
  checkYearPeriodQuery,
  filterstyle,
  uploadSuccessMessage,
  profitFileName,
  salesFileName,
  wasteFileName,
  dashboardTabName,
  baseApi,
} from "./../Constants/Constants";
import {
  CommonFlagConfigContext,
  CommonLogsDataConfigContext,
  CoreUserConfigContext,
  FileMetadataContext,
} from "../ContextAPI/ContextAPI";
import ModifyRequestForm from "../ModifyRequestForm/ModifyRequestForm";
import {
  checkFileEnableFlag,
  generateEndPeriod,
  generateEndYear,
  generateHistoryTableFilterValues,
  generateMarketValues,
  generateSelectDataset,
  generateUniqueMarketList,
  generateYearDropdownValues,
  slicerClassName,
  validateFileName,
} from "../CommonFunctions/CommonFunctions";
import {
  DownloadExcelTemplateFile,
  RefreshProcessingLogs,
} from "../CommonFunctions/CommonFunctions";
import FileNamePopup from "../Popup/FileNamePopup";
import icon_refresh from "../Images/icon-refresh.svg";
import { acquireToken } from "../AxiosInstance/AxiosInstance";

function updateDataConfiguration(
  fileMetaData: InputFileMetaData[],
  selectedMarket: string,
  selectedMetric: string,
  selectedFileName: string,
  selectedGeneration: string
): InputFileMetaData {
  let selectedFileMetaData: InputFileMetaData = fileMetaData.filter(
    (fileData: InputFileMetaData) =>
      fileData.Generation === selectedGeneration &&
      fileData.Market === selectedMarket &&
      fileData.Metric === selectedMetric &&
      fileData.FileName === selectedFileName
  )[0];
  return selectedFileMetaData;
}

async function uploadExceltemplate(
  file: any,
  fileMetaData: InputFileMetaData,
  username: string,
  dropdownConfigs: DropdownConfigurations,
  popupParameters: PopupParameter,
  setPopupParameters: Dispatch<SetStateAction<PopupParameter>>
) {
  let statusData: PopupParameter = defaultPopupParameter;
  statusData.list = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "multipart/form-data",
    },
  };
  let updatedMetadata: any = fileMetaData;
  updatedMetadata["username"] = username;
  let filedata = new FormData();
  filedata.append("file", file);
  filedata.append("FileId", fileMetaData.FileId.toString());
  filedata.append("FullOverrideFlag", dropdownConfigs.fullOverrideFile);
  filedata.append("StartYear", dropdownConfigs.startYear.toString());
  filedata.append("StartPeriod", dropdownConfigs.startPeriod.toString());
  filedata.append("EndYear", dropdownConfigs.endYear.toString());
  filedata.append("EndPeriod", dropdownConfigs.endPeriod.toString());
  filedata.append("UserName", username);
  await axios
    .post(baseApiUrl + uploadExcelFileURL, filedata, config)
    .then((response) => {
      statusData.isOpen = true;
      if (typeof response.data === "string") {
        statusData.list.push(response.data);
        statusData.isSuccess =
          response.data === uploadSuccessMessage ? true : false;
      } else {
        let length: number = response.data.length;
        for (let iterator: number = 0; iterator < length; iterator++) {
          statusData.list.push(response.data[iterator]);
        }
        statusData.isSuccess = false;
      }
      setPopupParameters({
        ...popupParameters,
        isOpen: true,
        list: statusData.list,
        isSuccess: statusData.isSuccess,
      });
    })
    .catch((err) => {
      console.log(err);
      statusData.isOpen = true;
      statusData.isSuccess = false;
      setPopupParameters({
        ...popupParameters,
        isOpen: true,
        list: [],
        isSuccess: false,
      });
    });
}

function updateDisableFlag(
  dropdownValues: DropdownConfigurations,
  buttonName: string,
  showFullOverrideFile?: string,
  file?: File | null
) {
  if (buttonName === "download") {
    return (
      dropdownValues.generation === "" ||
      dropdownValues.market === "" ||
      dropdownValues.metric === "" ||
      dropdownValues.fileName === ""
    );
  } else {
    if (dropdownValues.isRequired === 1) {
      return (
        dropdownValues.generation === "" ||
        dropdownValues.market === "" ||
        dropdownValues.metric === "" ||
        dropdownValues.fileName === "" ||
        (showFullOverrideFile === "Y" &&
          dropdownValues.fullOverrideFile === "") ||
        dropdownValues.startYear === "" ||
        dropdownValues.endYear === "" ||
        dropdownValues.startPeriod === "" ||
        dropdownValues.endPeriod === "" ||
        file === null ||
        validateFileName(dropdownValues.fileName, file?.name)
      );
    }
    return (
      dropdownValues.generation === "" ||
      dropdownValues.market === "" ||
      dropdownValues.metric === "" ||
      dropdownValues.fileName === "" ||
      (showFullOverrideFile === "Y" &&
        dropdownValues.fullOverrideFile === "") ||
      file === null ||
      validateFileName(dropdownValues.fileName, file?.name)
    );
  }
}

async function setIsYearPeriodRequired(
  dropdownValues: DropdownConfigurations,
  fileId: string,
  setDropDownValues: React.Dispatch<
    React.SetStateAction<DropdownConfigurations>
  >
) {
  if (dropdownValues.fileName !== "" && dropdownValues.isRequired === -1) {
    let query: GraphqlQueryParamater = checkYearPeriodQuery(fileId);

    const accessToken: string = await acquireToken();
    const baseApiUrl: string = await baseApi();
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(baseApiUrl + graphqlAPIURL, query, config)
      .then((response) => {
        setDropDownValues({
          ...dropdownValues,
          isRequired: response.data.data["CheckYearPeriod"],
        });
      });
  }
}

function checkIsYearPeriodRequired(flag: number, isIcon: boolean): string {
  return flag === 1 ? (isIcon ? "*" : "pointer") : isIcon ? "" : "overlay";
}

function inputFileClassName(
  filename: string,
  fullOverrideCheck: boolean
): string {
  let className = "disable-input";
  if (fullOverrideCheck && filename !== "") {
    className = "pointer";
  }
  return className;
}

function requestAccessButtonText(flag: boolean) {
  return flag ? "Hide " : "";
}

function ManualUpload(props: {
  allMarketList: string[];
  popupParameters: PopupParameter;
  setPopupParameters: Dispatch<SetStateAction<PopupParameter>>;
}) {
  const commonFlags = useContext(CommonFlagConfigContext);
  const fileMetaDataContext = useContext(FileMetadataContext);
  const coreUserConfig = useContext(CoreUserConfigContext);
  const logsDataConfigs = useContext(CommonLogsDataConfigContext);
  const [fileMetaData, setFileMetaData] = useState<InputFileMetaData[]>(
    fileMetaDataContext.data
  );
  const [isDisplayError, setIsDisplayError] = useState(false);
  const [uploadedFileName, setUploadedFileName] =
    useState<FileMetaDataConfiguration>({
      fileName: "No file chosen",
      file: null,
    });
  const [dropdownValues, setDropDownValues] = useState<DropdownConfigurations>({
    openedDropDown: "",
    generation: "",
    market: "",
    metric: "",
    fileName: "",
    fullOverrideFile: "",
    startYear: "",
    startPeriod: "",
    endYear: "",
    endPeriod: "",
    isRequired: -1,
  });

  const blankArray: string[] = [];
  const [dropdownConfigurations, setDropdownConfigurations] = useState({
    generation: blankArray,
    market: blankArray,
    metric: blankArray,
    FIleName: blankArray,
    fullOverrideFile: ["Y", "N"],
  });
  const [showFullOverrideFile, setShowFullOverrideFile] = useState<string>();

  const refreshFileProcessLog = async () => {
    RefreshProcessingLogs().then((response: any) => {
      if (response.length > 0) {
        const manualMarketFileIds = fileMetaDataContext.data
          .filter(
            (fileData: InputFileMetaData) => fileData.ManualMetric === "Y"
          )
          .map((fileData: InputFileMetaData) => fileData.FileId);
        const manualMarketProcessLogs: InputFileProcessLogMetaData[] =
          response.filter((metaData: InputFileProcessLogMetaData) => {
            return manualMarketFileIds.indexOf(metaData.FileId) === -1;
          });
        const historyTableFilterData: LogTableFilterConfigurations =
          generateHistoryTableFilterValues(manualMarketProcessLogs);
        logsDataConfigs.setData({
          ...logsDataConfigs.data,
          processLogMetadata: manualMarketProcessLogs,
          logsFilterData: historyTableFilterData,
          tabName: dashboardTabName,
        });
        commonFlags.setFlags({
          ...commonFlags.flags,
          isRefreshTableIcon: false,
          isShowUploadHistory: true,
          isShowRequestPage: false,
        });
      }
    });
  };
  const getFileMetaData = async () => {
    const accessToken: string = await acquireToken();
    const baseApiUrl: string = await baseApi();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios
      .post(baseApiUrl + graphqlAPIURL, inputFileMetadataQuery, config)
      .then((response: any) => {
        let fileMetaData: InputFileMetaData[] =
          response.data.data["InputFileMetadata"];
        if (fileMetaData.length > 0) {
          fileMetaDataContext.setData(fileMetaData);
          setFileMetaData(fileMetaData);
          commonFlags.setFlags({
            ...commonFlags.flags,
            isRefreshPageIcon: false,
          });
        }
      });
  };
  useEffect(() => {
    if (coreUserConfig.config.token && fileMetaDataContext.data.length === 0) {
      getFileMetaData();
    }
  }, [coreUserConfig.config.token]);

  const manualUploadMetaData = fileMetaData.filter(
    (metaData: InputFileMetaData) => metaData.ManualMetric !== "Y"
  );

  useEffect(() => {
    if (dropdownConfigurations.market.length === 0) {
      const marketValues: string[] =
        generateUniqueMarketList(manualUploadMetaData);
      setDropdownConfigurations({
        ...dropdownConfigurations,
        market: marketValues,
      });
    }
  }, [fileMetaData, dropdownConfigurations.market.length]);

  useEffect(() => {
    const fileNameFlag: boolean = validateFileName(
      dropdownValues.fileName,
      uploadedFileName.fileName
    );
    if (fileNameFlag) {
      setIsDisplayError(true);
      setUploadedFileName({
        fileName: "No file chosen",
        file: null,
      });
    }
  }, [uploadedFileName]);

  const data = updateDataConfiguration(
    manualUploadMetaData,
    dropdownValues.market,
    dropdownValues.metric,
    dropdownValues.fileName,
    dropdownValues.generation
  );

  setIsYearPeriodRequired(
    dropdownValues,
    data?.FileId.toString(),
    setDropDownValues
  );
  let yearDropdownValues: string[] = [];
  let endYearDropDown: string[] = [];
  let periodDropDownValues: string[] = [];
  let endPeriodValues: string[] = [];
  if (dropdownValues.isRequired === 1) {
    yearDropdownValues = generateYearDropdownValues();
    endYearDropDown = generateEndYear(
      yearDropdownValues,
      dropdownValues.startYear
    );
    periodDropDownValues = timePeriodValues;
    endPeriodValues = generateEndPeriod(
      dropdownValues.startYear,
      dropdownValues.endYear,
      dropdownValues.startPeriod
    );
  }
  const marketDropdownValues: string[] = generateMarketValues(
    coreUserConfig.config.BU,
    dropdownConfigurations.market
  );
  const fileInputRef: any = useRef(null);
  return (
    <>
      <div className="selection-text">
        <label className="selection-download-text">
          Please select Generation, Market, Metric value, and Filename to
          download the template file/upload the relevant file
        </label>
        {dropdownValues.isRequired === 1 && (
          <label className="selection-upload-text">
            <b className="highlight">*</b> Please select Start Year, Start
            Period, End Year, and End Period to upload the file:{" "}
            <b>{dropdownValues.fileName}</b>
          </label>
        )}
      </div>
      <div className="selection-dropdown">
        <div className="dropdown-row">
          <div className="market-section dropdown-list dropdown-col1">
            <label className="dropdown-title">Market</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(marketDropdownValues)}
              isSearchable={false}
              placeholder="Select Market"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.market
                ) {
                  dropdownValues.market = selectedOption.value;
                  dropdownValues.generation = "";
                  dropdownValues.metric = "";
                  dropdownValues.fileName = "";
                  dropdownValues.fullOverrideFile = "";
                  dropdownValues.isRequired = -1;
                  dropdownValues.startYear = "";
                  dropdownValues.startPeriod = "";
                  dropdownValues.endYear = "";
                  dropdownValues.endPeriod = "";
                  setShowFullOverrideFile(undefined);
                  let tempFileData: InputFileMetaData[] = manualUploadMetaData;
                  tempFileData = tempFileData.filter(
                    (fileData: InputFileMetaData) =>
                      fileData.Market === selectedOption.value
                  );
                  let generationValues: string[] = tempFileData
                    .map((item: InputFileMetaData) => item.Generation)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  generationValues.sort();
                  setDropdownConfigurations({
                    ...dropdownConfigurations,
                    generation: generationValues,
                    metric: blankArray,
                    FIleName: blankArray,
                  });
                }
              }}
            />
          </div>
          <div className="generation-section dropdown-list dropdown-col2">
            <label className="dropdown-title">Generation</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(dropdownConfigurations.generation)}
              isSearchable={false}
              placeholder="Select Generation"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-" + dropdownValues.market}
              isDisabled={slicerClassName(dropdownValues.market)}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.generation
                ) {
                  dropdownValues.generation = selectedOption.value;
                  dropdownValues.metric = "";
                  dropdownValues.fileName = "";
                  dropdownValues.fullOverrideFile = "";
                  dropdownValues.isRequired = -1;
                  dropdownValues.startYear = "";
                  dropdownValues.startPeriod = "";
                  dropdownValues.endYear = "";
                  dropdownValues.endPeriod = "";
                  setShowFullOverrideFile(undefined);
                  let tempFileData: InputFileMetaData[] = manualUploadMetaData;
                  tempFileData = tempFileData.filter(
                    (fileData: InputFileMetaData) =>
                      fileData.Market === dropdownValues.market &&
                      fileData.Generation === selectedOption.value
                  );
                  let metricValues: string[] = tempFileData
                    .map((item: InputFileMetaData) => item.Metric)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  metricValues.sort();
                  setDropdownConfigurations({
                    ...dropdownConfigurations,
                    metric: metricValues,
                    FIleName: blankArray,
                  });
                }
              }}
            />
          </div>
          <div className="metric-section dropdown-list dropdown-col3">
            <label className="dropdown-title">Metric Value</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(dropdownConfigurations.metric)}
              isSearchable={false}
              placeholder="Select Metric Value"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-metric" + dropdownValues.generation}
              isDisabled={slicerClassName(dropdownValues.generation)}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.metric
                ) {
                  dropdownValues.metric = selectedOption.value;
                  dropdownValues.fileName = "";
                  dropdownValues.fullOverrideFile = "";
                  dropdownValues.isRequired = -1;
                  dropdownValues.startYear = "";
                  dropdownValues.startPeriod = "";
                  dropdownValues.endYear = "";
                  dropdownValues.endPeriod = "";
                  setShowFullOverrideFile(undefined);
                  let tempFileData: InputFileMetaData[] = manualUploadMetaData;
                  tempFileData = tempFileData.filter(
                    (fileData: InputFileMetaData) =>
                      fileData.Generation === dropdownValues.generation &&
                      fileData.Market === dropdownValues.market &&
                      fileData.Metric === selectedOption.value
                  );
                  let fileNames: string[] = tempFileData
                    .map((item: InputFileMetaData) => item.FileName)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  fileNames.sort();
                  setDropdownConfigurations({
                    ...dropdownConfigurations,
                    FIleName: fileNames,
                  });
                }
              }}
            />
          </div>
          <div className="file-section dropdown-list dropdown-col4">
            <label className="dropdown-title">File Name</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(dropdownConfigurations.FIleName)}
              isSearchable={false}
              placeholder="Select File"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-" + dropdownValues.metric}
              isDisabled={slicerClassName(dropdownValues.metric)}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.fileName
                ) {
                  dropdownValues.fileName = selectedOption.value;
                  dropdownValues.fullOverrideFile = "";
                  dropdownValues.isRequired = -1;
                  dropdownValues.startYear = "";
                  dropdownValues.startPeriod = "";
                  dropdownValues.endYear = "";
                  dropdownValues.endPeriod = "";
                  setDropdownConfigurations({
                    ...dropdownConfigurations,
                    FIleName: dropdownConfigurations.FIleName,
                  });
                  setShowFullOverrideFile(
                    manualUploadMetaData.filter(
                      (fileData: InputFileMetaData) =>
                        fileData.Generation === dropdownValues.generation &&
                        fileData.Market === dropdownValues.market &&
                        fileData.Metric === dropdownValues.metric &&
                        fileData.FileName === selectedOption.value
                    )[0]?.FullOverrideFlagRequired
                  );
                }
              }}
            />
          </div>
          {showFullOverrideFile === "Y" && (
            <div className="full-override-file-section dropdown-list">
              <label className="dropdown-title">Full Override File</label>
              <Select
                classNamePrefix="react-select"
                options={generateSelectDataset(
                  dropdownConfigurations.fullOverrideFile
                )}
                value={generateSelectDataset(
                  dropdownConfigurations.fullOverrideFile
                ).filter(
                  (item) => item.value === dropdownValues.fullOverrideFile
                )}
                isSearchable={false}
                placeholder="Full Override File"
                isClearable={false}
                hideSelectedOptions={false}
                styles={filterstyle}
                key={"react-select-" + dropdownValues.metric}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setDropDownValues({
                      ...dropdownValues,
                      fullOverrideFile: selectedOption.value,
                    });
                  }
                }}
              />
            </div>
          )}
          {dropdownValues.isRequired === 1 && (
            <>
              <div
                className={`start-year-section dropdown-list ${
                  showFullOverrideFile === "Y"
                    ? "dropdown-row2-cols"
                    : "dropdown-col1"
                }`}
              >
                <label className="dropdown-title">
                  Start Year{" "}
                  <b className="highlight">
                    {checkIsYearPeriodRequired(dropdownValues.isRequired, true)}
                  </b>
                </label>
                <Select
                  classNamePrefix="react-select"
                  options={generateSelectDataset(yearDropdownValues)}
                  isSearchable={false}
                  placeholder="Select Year"
                  isClearable={false}
                  hideSelectedOptions={false}
                  styles={filterstyle}
                  key={"react-select-" + dropdownValues.fileName}
                  onChange={(selectedOption) => {
                    if (
                      selectedOption &&
                      selectedOption.value !== dropdownValues.startYear
                    ) {
                      dropdownValues.startYear = selectedOption.value;
                      dropdownValues.endYear = "";
                      dropdownValues.endPeriod = "";
                    }
                    setDropDownValues({
                      ...dropdownValues,
                      openedDropDown: "",
                    });
                  }}
                />
              </div>
              <div
                className={`start-period-section dropdown-list ${
                  showFullOverrideFile === "Y"
                    ? "dropdown-row2-cols"
                    : "dropdown-col2"
                }`}
              >
                <label className="dropdown-title">
                  Start Period{" "}
                  <b className="highlight">
                    {checkIsYearPeriodRequired(dropdownValues.isRequired, true)}
                  </b>
                </label>
                <Select
                  classNamePrefix="react-select"
                  options={generateSelectDataset(periodDropDownValues)}
                  isSearchable={false}
                  placeholder="Select Period"
                  isClearable={false}
                  hideSelectedOptions={false}
                  styles={filterstyle}
                  key={"react-select-period" + dropdownValues.fileName}
                  onChange={(selectedOption) => {
                    if (
                      selectedOption &&
                      selectedOption.value !== dropdownValues.startPeriod
                    ) {
                      dropdownValues.startPeriod = selectedOption.value;
                      dropdownValues.endPeriod = "";
                      setDropDownValues({
                        ...dropdownValues,
                        openedDropDown: "",
                      });
                    }
                  }}
                />
              </div>
              <div
                className={`end-year-section dropdown-list ${
                  showFullOverrideFile === "Y"
                    ? "dropdown-row2-cols"
                    : "dropdown-col3"
                }`}
              >
                <label className="dropdown-title">
                  End Year{" "}
                  <b className="highlight">
                    {checkIsYearPeriodRequired(dropdownValues.isRequired, true)}
                  </b>
                </label>
                <Select
                  classNamePrefix="react-select"
                  options={generateSelectDataset(endYearDropDown)}
                  isSearchable={false}
                  placeholder="Select Year"
                  isClearable={false}
                  hideSelectedOptions={false}
                  styles={filterstyle}
                  key={"react-select-year" + dropdownValues.startYear}
                  onChange={(selectedOption) => {
                    if (
                      selectedOption &&
                      selectedOption.value !== dropdownValues.endYear
                    ) {
                      dropdownValues.endYear = selectedOption.value;
                      dropdownValues.endPeriod =
                        dropdownValues.startYear === selectedOption.value &&
                        dropdownValues.endPeriod < dropdownValues.startPeriod
                          ? ""
                          : dropdownValues.endPeriod;
                      setDropDownValues({
                        ...dropdownValues,
                        openedDropDown: "",
                      });
                    }
                  }}
                />
              </div>
              <div
                className={`end-period-section dropdown-list ${
                  showFullOverrideFile === "Y"
                    ? "dropdown-row2-cols"
                    : "dropdown-col4"
                }`}
              >
                <label className="dropdown-title">
                  End Period{" "}
                  <b className="highlight">
                    {checkIsYearPeriodRequired(dropdownValues.isRequired, true)}
                  </b>
                </label>
                <Select
                  classNamePrefix="react-select"
                  options={generateSelectDataset(endPeriodValues)}
                  isSearchable={false}
                  placeholder="Select Period"
                  isClearable={false}
                  hideSelectedOptions={false}
                  styles={filterstyle}
                  key={
                    "react-select-start-period" +
                    dropdownValues.startPeriod +
                    dropdownValues.endYear
                  }
                  onChange={(selectedOption) => {
                    if (
                      selectedOption &&
                      selectedOption.value !== dropdownValues.endPeriod
                    ) {
                      dropdownValues.endPeriod = selectedOption.value;
                      setDropDownValues({
                        ...dropdownValues,
                        openedDropDown: "",
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="template-btn">
          <div className="download-btn">
            <button
              onClick={() => {
                DownloadExcelTemplateFile(data.FileId, data.FileName);
              }}
              disabled={updateDisableFlag(dropdownValues, "download")}
            >
              Download Template
            </button>
          </div>
          <div
            className={
              "download-template-row " +
              (dropdownValues.isRequired === 1 ? "" : "left-btn")
            }
          >
            <div className="upload-parent-section">
              <div className="upload-section">
                <div className="upload-btn-wrap">
                  <label className="dropdown-title">File Attachment</label>
                  <div className={"custom-upload"}>
                    <div className="selected-file-label">
                      {uploadedFileName.fileName}
                    </div>
                    <label
                      className={
                        "upload-btn " +
                        inputFileClassName(
                          dropdownValues.fileName,
                          !(
                            showFullOverrideFile === "Y" &&
                            dropdownValues.fullOverrideFile === ""
                          )
                        )
                      }
                      htmlFor="upload"
                    >
                      <label className="upload-text" htmlFor="upload">
                        Choose File
                      </label>
                    </label>
                  </div>
                  <input
                    id="upload"
                    type="file"
                    disabled={checkFileEnableFlag(dropdownValues.fileName)}
                    onChange={(event) => {
                      let inputFile = event.target.files
                        ? event.target.files[0]
                        : null;
                      if (inputFile) {
                        setUploadedFileName({
                          fileName: inputFile.name,
                          file: inputFile,
                        });
                        event.target.value = "";
                      }
                    }}
                    multiple={false}
                    ref={fileInputRef}
                  />
                </div>
              </div>
            </div>
            <div className="template-btn-upload">
              <button
                onClick={async () => {
                  commonFlags.setFlags({
                    ...commonFlags.flags,
                    isRefreshPageIcon: true,
                  });
                  if (uploadedFileName.file) {
                    await uploadExceltemplate(
                      uploadedFileName.file,
                      data,
                      coreUserConfig.config.userEmail,
                      dropdownValues,
                      props.popupParameters,
                      props.setPopupParameters
                    );
                    commonFlags.setFlags({
                      ...commonFlags.flags,
                      isRefreshPageIcon: false,
                    });
                  }
                }}
                disabled={updateDisableFlag(
                  dropdownValues,
                  "upload",
                  showFullOverrideFile,
                  uploadedFileName.file
                )}
              >
                Upload Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row-btn">
        <div className="logs-template-btn">
          <button
            onClick={() => {
              if (!commonFlags.flags.isShowUploadHistory) {
                refreshFileProcessLog();
              } else {
                commonFlags.setFlags({
                  ...commonFlags.flags,
                  isShowUploadHistory: false,
                });
              }
            }}
          >
            {commonFlags.flags.isShowUploadHistory ? "Hide" : "Show"} Upload
            History
          </button>
        </div>
        <div
          className="logs-template-btn"
          onClick={() => {
            commonFlags.setFlags({
              ...commonFlags.flags,
              isShowUploadHistory: false,
              isShowRequestPage: !commonFlags.flags.isShowRequestPage,
            });
          }}
        >
          <button>
            {requestAccessButtonText(commonFlags.flags.isShowRequestPage)}
            Request Access
          </button>
        </div>
        {commonFlags.flags.isShowUploadHistory && (
          <div
            className="refresh-btn"
            onClick={() => {
              commonFlags.setFlags({
                ...commonFlags.flags,
                isRefreshTableIcon: true,
              });
              refreshFileProcessLog();
            }}
          >
            <img
              className={
                "refresh-icon " +
                (commonFlags.flags.isRefreshTableIcon ? "rotate-icon" : "")
              }
              src={icon_refresh}
              alt="refresh"
            />
            <label
              className="refresh-label"
              onClick={() => {
                commonFlags.setFlags({
                  ...commonFlags.flags,
                  isRefreshTableIcon: true,
                });
                refreshFileProcessLog();
              }}
            >
              Refresh Data
            </label>
          </div>
        )}
        {commonFlags.flags.isShowRequestPage && (
          <ModifyRequestForm
            allMarketList={props.allMarketList}
            approvedMarketList={marketDropdownValues}
          />
        )}
      </div>
      {isDisplayError && (
        <FileNamePopup
          fileName={dropdownValues.fileName}
          setIsDisplayError={setIsDisplayError}
        />
      )}
    </>
  );
}
export default ManualUpload;
