import { createContext } from "react";
import {
  CommonFlagConfigurations,
  CommonLogsDataConfigurations,
  CoreUserConfig,
  InputFileMetaData,
} from "../Interface/Interface";
import {
  defaultCommonFlags,
  defaultCoreUserConfig,
  defaultLogsDataConfigurations,
} from "../Constants/Constants";

export const CoreUserConfigContext = createContext<{
  config: CoreUserConfig;
  setConfig: (newValue: CoreUserConfig) => void;
}>({
  config: defaultCoreUserConfig,
  setConfig: () => undefined,
});

export const CommonFlagConfigContext = createContext<{
  flags: CommonFlagConfigurations;
  setFlags: (newValue: CommonFlagConfigurations) => void;
}>({
  flags: defaultCommonFlags,
  setFlags: () => undefined,
});

export const CommonLogsDataConfigContext = createContext<{
  data: CommonLogsDataConfigurations;
  setData: (newValue: CommonLogsDataConfigurations) => void;
}>({
  data: defaultLogsDataConfigurations,
  setData: () => undefined,
});

export const FileMetadataContext = createContext<{
  data: InputFileMetaData[];
  setData: (newValue: InputFileMetaData[]) => void;
}>({
  data: [],
  setData: () => undefined,
});
