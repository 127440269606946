import React from "react";
import ModifyRequestForm from "../ModifyRequestForm/ModifyRequestForm";

function RequestForm(props: { allMarketList: string[] }) {
  return (
    <>
      <div className="selection-text">
        <label className="selection-download-text">
          <b>
            Access Denied. Please reach out to HCM support for assistance or
            submit below form.
          </b>
        </label>
      </div>
      <ModifyRequestForm allMarketList={props.allMarketList} />
    </>
  );
}
export default RequestForm;
