import { Dispatch, SetStateAction } from "react";
import icon_connection_success from "../Images/icon-connection-success.svg";
import Icon_close_circle from "./../Images/Icon-close-circle.svg";
import "./Popup.css";

function AdminPopup(props: {
  message: string;
  setApprovalMessagePopup: Dispatch<SetStateAction<boolean>>;
  setApprovalMessage: Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="popup-bgblur">
      <div className="popup-layer">
        <div
          className="close-icon"
          onClick={() => {
            props.setApprovalMessagePopup(false);
            props.setApprovalMessage("");
          }}
        >
          <img src={Icon_close_circle} alt="" />
        </div>
        <div className="popup-title popup-status-message">
          {" "}
          <img className="status-icon" src={icon_connection_success} alt="" />
          <label className="status-text">{props.message}</label>
        </div>
      </div>
    </div>
  );
}
export default AdminPopup;
