import { Dispatch, SetStateAction } from "react";
import { RequestStatusConfigurations } from "../Interface/Interface";
import icon_connection_success from "../Images/icon-connection-success.svg";
import Icon_close_circle from "./../Images/Icon-close-circle.svg";
import "./Popup.css";

function RequestFormPopup(props: {
  requestStatusDetails: RequestStatusConfigurations[];
  setRequestStatusDetails: Dispatch<
    SetStateAction<RequestStatusConfigurations[]>
  >;
  setRequestPopupFlag: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="popup-bgblur">
      <div className="popup-layer">
        <div
          className="close-icon"
          onClick={() => {
            props.setRequestPopupFlag(false);
            props.requestStatusDetails = [];
            props.setRequestStatusDetails([]);
          }}
        >
          <img src={Icon_close_circle} alt="" />
        </div>
        <div className="popup-title popup-status-message">
          {" "}
          <img className="status-icon" src={icon_connection_success} alt="" />
          <label className="status-text">Request Status!</label>
          <div className="request-status-list">
            <ul>
              {props.requestStatusDetails.map(
                (list: RequestStatusConfigurations) => {
                  return (
                    <li>
                      <b>{list.buName}</b>: {list.message}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RequestFormPopup;
