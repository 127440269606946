import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import {
  BURoleConfig,
  UserRoleDataConfigurations,
} from "../Interface/Interface";
import { fetchUserRoleData } from "../CommonFunctions/CommonFunctions";
import icon_search from "../Images/icon-search-black.svg";
import "./UserDetails.css";

function UserDetails(props: { setOpenSearchPopupFlag: Dispatch<boolean> }) {
  const [searchKey, setSearchKey] = useState({
    value: "",
    isGPID: false,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [userRoleData, setUserRoleData] = useState<UserRoleDataConfigurations>({
    gpid: "",
    emailID: "",
    buRoles: [],
  });
  const [showNote, setShowNote] = useState(false);
  const fetchGPIDDetails = () => {
    setShowLoader(true);
    fetchUserRoleData(searchKey.value, searchKey.isGPID).then((data) => {
      setShowLoader(false);
      setShowNote(true);
      if (data.buRoles.length > 0) {
        const userRoleData = data.buRoles.filter((data) => {
          return data.roleType === "CORE_USER";
        });
        setUserRoleData({
          gpid: data.gpid,
          emailID: data.emailID,
          buRoles: userRoleData,
        });
      }
    });
  };
  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.setOpenSearchPopupFlag(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div className="search-popup-box" ref={wrapperRef}>
      <div className="search-heading">Search User</div>
      <div className="search-type">
        <label className="search-type-title">Search by:</label>
        <div className="radio-option">
          <input
            type="radio"
            name="search-type"
            id="search-type"
            defaultChecked={true}
            onClick={() => {
              setUserRoleData({ gpid: "", emailID: "", buRoles: [] });
              setSearchKey({ ...searchKey, isGPID: false });
              setShowNote(false);
            }}
          />
          Email ID
        </div>
        <div className="radio-option">
          <input
            type="radio"
            name="search-type"
            id="search-type"
            onClick={() => {
              setUserRoleData({ gpid: "", emailID: "", buRoles: [] });
              setSearchKey({ ...searchKey, isGPID: true });
            }}
          />
          GPID
        </div>
      </div>
      <div className="search-section">
        <input
          type="text"
          className="user-search-box search-box"
          placeholder="Search"
          onChange={(event) => {
            setSearchKey({ ...searchKey, value: event.target.value });
          }}
        />
        <img
          className="search-icon"
          src={icon_search}
          onClick={() => {
            fetchGPIDDetails();
            setShowNote(false);
          }}
        />
      </div>
      <div className="user-details-section">
        {showLoader ? (
          <div className="loader-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <>
            {showNote && (
              <>
                {userRoleData.buRoles.length > 0 ? (
                  <div className="access-note">
                    <b>Note</b>: This list contains only approved requests.
                  </div>
                ) : (
                  <div className="access-note">
                    The user doesn't have core user access for any Market.
                  </div>
                )}
              </>
            )}
            <div className="user-info">
              <label className="user-title">Email ID: </label>
              <label className="user-info-value">{userRoleData.emailID}</label>
            </div>
            <div className="user-info">
              <label className="user-title">GPID: </label>
              <label className="user-info-value">{userRoleData.gpid}</label>
            </div>
            <div className="user-details-table">
              <table className="user-bu-details">
                {userRoleData.buRoles.map(
                  (buRole: BURoleConfig, index: number) => {
                    return (
                      <>
                        {index === 0 && (
                          <tr className="bu-role-row row-header">
                            <th className="row-value">Market</th>
                            <th className="row-value">Role</th>
                          </tr>
                        )}
                        <tr className="bu-role-row">
                          <td className="row-value">{buRole.buName}</td>
                          <td className="row-value">{buRole.roleType}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              </table>
            </div>
          </>
        )}
      </div>
      <div className="search-popup-footer">
        <button
          className="search-close-btn"
          onClick={() => {
            props.setOpenSearchPopupFlag(false);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}
export default UserDetails;
