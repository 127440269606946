import { Dispatch, SetStateAction } from "react";
import icon_connection_failed from "../Images/icon-connection-failed.svg";
import Icon_close_circle from "./../Images/Icon-close-circle.svg";
import "./Popup.css";

function FileNamePopup(props: {
  fileName: string;
  setIsDisplayError: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="popup-bgblur">
      <div className="popup-layer">
        <div
          className="close-icon"
          onClick={() => {
            props.setIsDisplayError(false);
          }}
        >
          <img src={Icon_close_circle} alt="" />
        </div>
        <div className="popup-title popup-status-message">
          <img className="status-icon" src={icon_connection_failed} alt="" />
          <label className="status-text">
            Filename should be {props.fileName}.xlsx
          </label>
        </div>
      </div>
    </div>
  );
}
export default FileNamePopup;
