import { Dispatch, SetStateAction } from "react";

function CommentPopup(props: {
  comment: string;
  index: number;
  commentPopupConfig: any;
  setCommentPopupConfig: Dispatch<SetStateAction<any>>;
}) {
  let className = "hide";
  if (
    props.index === props.commentPopupConfig.index &&
    (props.commentPopupConfig.isMouseInPopup ||
      props.commentPopupConfig.isMouseInIcon)
  ) {
    className = "";
  }
  return (
    <div
      className={"comment-popup " + className}
      onMouseEnter={() => {
        props.setCommentPopupConfig({
          ...props.commentPopupConfig,
          isMouseInPopup: true,
          isMouseInIcon: false,
        });
      }}
      onMouseLeave={() => {
        props.setCommentPopupConfig({
          ...props.commentPopupConfig,
          isMouseInPopup: false,
          isMouseInIcon: false,
        });
      }}
    >
      <label>{props.comment}</label>
    </div>
  );
}
export default CommentPopup;
