import { Dispatch, SetStateAction } from "react";
import { selectAllText } from "../Constants/Constants";
import { filterValueClassName } from "../CommonFunctions/CommonFunctions";

function PopulateAllDropDownValues(values: string[]): string[] {
  let dropdownValues: string[] = [];
  for (let iterator = 0; iterator < values.length; iterator++) {
    dropdownValues.push(values[iterator]);
  }
  return dropdownValues;
}

function FilterPopup(props: {
  dropdownValues: string[];
  state: string;
  tableDropDown: any;
  setTableDropDowns: Dispatch<SetStateAction<any>>;
  wrapperRef: React.MutableRefObject<null>;
}) {
  const slicerUniqueValues: string[] = PopulateAllDropDownValues(
    props.dropdownValues
  );
  return (
    <div className="filter-popup" ref={props.wrapperRef}>
      <div className="filter-popup-layer">
        {props.dropdownValues.map((value: string) => {
          return (
            <div
              className={
                "filter-values " +
                filterValueClassName(props.tableDropDown[props.state], value)
              }
              title={value}
              onClick={() => {
                let selectedValues: string[] = props.tableDropDown[props.state];
                let index: number = -1;
                let selectAllIndex: number = -1;
                if (selectedValues.length > 0) {
                  index = selectedValues.indexOf(value);
                  selectAllIndex = selectedValues.indexOf(selectAllText);
                }
                if (value === selectAllText && index === -1) {
                  selectedValues = slicerUniqueValues;
                } else if (value === selectAllText && index !== -1) {
                  selectedValues = [];
                } else {
                  if (index === -1) {
                    selectedValues.push(value);
                  } else if (selectAllIndex === -1) {
                    selectedValues.splice(index, 1);
                  } else {
                    selectedValues.splice(index, 1);
                    const updatedSelectAllIndex: number =
                      selectedValues.indexOf(selectAllText);
                    selectedValues.splice(updatedSelectAllIndex, 1);
                  }
                }
                const selectedValuesCount: number = selectedValues.length;
                if (
                  selectedValuesCount === slicerUniqueValues.length - 1 &&
                  selectAllIndex === -1
                ) {
                  selectedValues.push(selectAllText);
                }
                props.setTableDropDowns({
                  ...props.tableDropDown,
                  [props.state]: selectedValues,
                });
              }}
            >
              <input
                className="value-check"
                type="checkbox"
                checked={
                  filterValueClassName(
                    props.tableDropDown[props.state],
                    value
                  ) === "selected-value"
                }
              />
              <label className="value-label">{value}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default FilterPopup;
