import { useContext, useEffect, useRef, useState } from "react";
import { parseISO, format } from "date-fns";
import axios, { AxiosRequestConfig } from "axios";
import {
  InputFileProcessLogMetaData,
  LogTableDropDownState,
} from "../Interface/Interface";
import icon_info_red from "./../Images/icon-info-red.svg";
import icon_filter_solid from "./../Images/icon-filter-outline.svg";
import {
  rowsProcessedExcelFileURL,
  selectAllText,
} from "../Constants/Constants";
import FilterPopup from "./FilterPopup";
import CommentPopup from "./CommentPopup";
import {
  CommonLogsDataConfigContext,
  CoreUserConfigContext,
} from "../ContextAPI/ContextAPI";
import "./table.css";

function FilterProcessLogData(
  processLogData: InputFileProcessLogMetaData[],
  tableDropDown: LogTableDropDownState
): InputFileProcessLogMetaData[] {
  let processLogMetaData: InputFileProcessLogMetaData[] = processLogData.sort(
    (
      firstValue: InputFileProcessLogMetaData,
      secondValue: InputFileProcessLogMetaData
    ) => {
      if (firstValue.UploadedDateTime > secondValue.UploadedDateTime) {
        return -1;
      } else {
        return 1;
      }
    }
  );
  if (tableDropDown.user.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.user.indexOf(logData.UploadedBy) !== -1
    );
  }
  if (tableDropDown.generation.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.generation.indexOf(logData.Generation) !== -1
    );
  }
  if (tableDropDown.market.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.market.indexOf(logData.Market) !== -1
    );
  }
  if (tableDropDown.metric.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.metric.indexOf(logData.Metric) !== -1
    );
  }
  if (tableDropDown.FIleName.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.FIleName.indexOf(logData.FileName) !== -1
    );
  }
  if (tableDropDown.status.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.status.indexOf(logData.Status) !== -1
    );
  }
  if (tableDropDown.startYearPeriod.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.startYearPeriod.indexOf(
          logData.StartYearPeriod.toString()
        ) !== -1
    );
  }
  if (tableDropDown.endYearPeriod.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.endYearPeriod.indexOf(
          logData.EndYearPeriod.toString()
        ) !== -1
    );
  }
  if (tableDropDown.fullOverrideFlag.length > 0) {
    processLogMetaData = processLogMetaData.filter(
      (logData: InputFileProcessLogMetaData) =>
        tableDropDown.fullOverrideFlag.indexOf(
          logData.FullOverrideFlag.toString()
        ) !== -1
    );
  }

  return processLogMetaData;
}

function TableHeaderColumnLabel(values: string[], count: number): string {
  let labelText = "";
  let selectAllIndex: number = values.indexOf(selectAllText);
  count = selectAllIndex !== -1 ? count - 1 : count;
  if (count > 0) {
    labelText = "(" + count + ")";
  }
  return labelText;
}

function DownloadRowsProcessedExcel(
  data: InputFileProcessLogMetaData,
  token: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "application/json",
    },
    responseType: "blob",
    maxBodyLength: Infinity,
  };
  let templatedata = {
    UploadedBy: data.UploadedBy,
    UploadedDateTime: data.UploadedDateTime,
    FileName: data.FileName,
    Generation: data.Generation,
    Market: data.Market,
  };
  axios
    .post(rowsProcessedExcelFileURL, templatedata, config)
    .then((response) => {
      let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.FileName.toLowerCase() + `_Log.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.remove();
    });
}

function Table() {
  const logsDataConfigs = useContext(CommonLogsDataConfigContext);
  const [tableDropDown, setTableDropDowns] = useState<LogTableDropDownState>({
    user: [],
    generation: [],
    market: [],
    metric: [],
    FIleName: [],
    status: [],
    startYearPeriod: [],
    endYearPeriod: [],
    fullOverrideFlag: [],
  });
  const [tableDropDownColumn, setTableDropDownColumn] = useState<string>("");
  const [commentPopupConfig, setCommentPopupConfig] = useState({
    index: "",
    isMouseInPopup: false,
    isMouseInIcon: false,
  });
  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTableDropDownColumn("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const tableData = FilterProcessLogData(
    logsDataConfigs.data.processLogMetadata,
    tableDropDown
  );
  return (
    <div className="table_div">
      <table className="table_container">
        <thead className="table_header_container">
          <tr className="table_row">
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Uploaded By{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.user,
                    tableDropDown.user.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn(
                      tableDropDownColumn === "" ? "user" : ""
                    );
                  }}
                />
              </div>
              {tableDropDownColumn === "user" && (
                <FilterPopup
                  dropdownValues={logsDataConfigs.data.logsFilterData.user}
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Generation{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.generation,
                    tableDropDown.generation.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("generation");
                  }}
                />
              </div>
              {tableDropDownColumn === "generation" && (
                <FilterPopup
                  dropdownValues={
                    logsDataConfigs.data.logsFilterData.generation
                  }
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Market{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.market,
                    tableDropDown.market.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("market");
                  }}
                />
              </div>
              {tableDropDownColumn === "market" && (
                <FilterPopup
                  dropdownValues={logsDataConfigs.data.logsFilterData.market}
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Metric{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.metric,
                    tableDropDown.metric.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("metric");
                  }}
                />
              </div>
              {tableDropDownColumn === "metric" && (
                <FilterPopup
                  dropdownValues={logsDataConfigs.data.logsFilterData.metric}
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  File Name{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.FIleName,
                    tableDropDown.FIleName.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("FIleName");
                  }}
                />
              </div>
              {tableDropDownColumn === "FIleName" && (
                <FilterPopup
                  dropdownValues={logsDataConfigs.data.logsFilterData.FIleName}
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Status{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.status,
                    tableDropDown.status.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("status");
                  }}
                />
              </div>
              {tableDropDownColumn === "status" && (
                <FilterPopup
                  dropdownValues={logsDataConfigs.data.logsFilterData.status}
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">Rows Processed</th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Full Override File{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.fullOverrideFlag,
                    tableDropDown.fullOverrideFlag.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("fullOverrideFlag");
                  }}
                />
              </div>
              {tableDropDownColumn === "fullOverrideFlag" && (
                <FilterPopup
                  dropdownValues={
                    logsDataConfigs.data.logsFilterData.fullOverrideFlag
                  }
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  Start Year Period{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.startYearPeriod,
                    tableDropDown.startYearPeriod.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("startYearPeriod");
                  }}
                />
              </div>
              {tableDropDownColumn === "startYearPeriod" && (
                <FilterPopup
                  dropdownValues={
                    logsDataConfigs.data.logsFilterData.startYearPeriod
                  }
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">
              <div className="col-header-label">
                <label className="col-header-label">
                  End Year Period{" "}
                  {TableHeaderColumnLabel(
                    tableDropDown.endYearPeriod,
                    tableDropDown.endYearPeriod.length
                  )}
                </label>
                <img
                  className="arrow-icon"
                  src={icon_filter_solid}
                  alt=""
                  onClick={() => {
                    setTableDropDownColumn("endYearPeriod");
                  }}
                />
              </div>
              {tableDropDownColumn === "endYearPeriod" && (
                <FilterPopup
                  dropdownValues={
                    logsDataConfigs.data.logsFilterData.endYearPeriod
                  }
                  state={tableDropDownColumn}
                  tableDropDown={tableDropDown}
                  setTableDropDowns={setTableDropDowns}
                  wrapperRef={wrapperRef}
                />
              )}
            </th>
            <th className="table_col col-header">Uploaded Date Time (UTC)</th>
            <th className="table_col col-header">Comments</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row: InputFileProcessLogMetaData, index: any) => (
            <tr className="table_row" key={index}>
              <td className="table_col_def">{row.UploadedBy}</td>
              <td className="table_col_def">{row.Generation}</td>
              <td className="table_col_def">{row.Market}</td>
              <td className="table_col_def">{row.Metric}</td>
              <td className="table_col_def">{row.FileName}</td>
              <td
                className={
                  "table_col_def " + (row.Status === "Failed" ? "failed" : "")
                }
              >
                {row.Status}
                {row.Status === "Failed" && (
                  <img
                    className="failed-icon"
                    src={icon_info_red}
                    alt=""
                    onMouseEnter={() => {
                      commentPopupConfig.isMouseInIcon = false;
                      commentPopupConfig.isMouseInPopup = true;
                      setCommentPopupConfig({
                        ...commentPopupConfig,
                        index: index,
                      });
                    }}
                    onMouseLeave={() => {
                      commentPopupConfig.isMouseInIcon = false;
                      commentPopupConfig.isMouseInPopup = false;
                      setCommentPopupConfig({
                        ...commentPopupConfig,
                        index: index,
                      });
                    }}
                  />
                )}
                <CommentPopup
                  comment={row.Comments}
                  index={index}
                  commentPopupConfig={commentPopupConfig}
                  setCommentPopupConfig={setCommentPopupConfig}
                />
              </td>
              <td className="table_col_def">
                <label>{row.RowsProcessed}</label>
              </td>
              <td className="table_col_def">{row.FullOverrideFlag}</td>
              <td className="table_col_def">{row.StartYearPeriod}</td>
              <td className="table_col_def">{row.EndYearPeriod}</td>
              <td className="table_col_def">
                {format(
                  parseISO(row.UploadedDateTime.toString()),
                  "yyyy-MM-dd HH:mm:ss"
                )}
              </td>
              <td
                className={
                  "table_col_def comment-col " +
                  (row.Status !== "Failed" ? "success" : "")
                }
              >
                {row.Status === "Failed" ? (
                  <textarea
                    className={"comment-section"}
                    id={"row" + index}
                    key={index}
                    rows={1}
                  >
                    {row.Comments}
                  </textarea>
                ) : (
                  row.Comments
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
