import { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { Security, LoginCallback, withOktaAuth } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { defaultCoreUserConfig } from "./Constants/Constants";
import { CoreUserConfig } from "./Interface/Interface";
import { CoreUserConfigContext } from "./ContextAPI/ContextAPI";
import App from "./App";

const AppWithOktaAuth = withOktaAuth(App);

function AuthenticationModel() {
  const baseURL: string = window.location.origin;

  const [oktaConfig, setOktaConfig] = useState({
    oidc: {
      clientId: "",
      issuer: "",
      redirectUri: baseURL + "/login/callback",
      scopes: ["openid", "profile", "email"],
      pkce: true,
    },
  });

  const [oktaAuth, setOktaAuth] = useState<OktaAuth>();
  useEffect(() => {
    fetch("/env/env.json")
      .then((obj) => obj.json())
      .then((data) => {
        setOktaConfig({
          oidc: {
            clientId: data.Client_Id,
            issuer: data.Issuer,
            redirectUri: baseURL + "/login/callback",
            scopes: ["openid", "profile", "email"],
            pkce: true,
          },
        });
      });
  }, []);

  useEffect(() => {
    if (oktaConfig.oidc.clientId !== "") {
      setOktaAuth(new OktaAuth(oktaConfig.oidc));
    }
  }, [oktaConfig]);

  const navigate = useHistory(); // example from react-router
  const [coreUserConfig, setCoreUserConfig] = useState<CoreUserConfig>({
    ...defaultCoreUserConfig,
    userEmail: "",
    gpid: "",
    userName: "",
  });
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const onAuthRequired = function () {
    navigate.push("/login");
  };

  return oktaAuth ? (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <Route path="/login/callback" exact={true} component={LoginCallback} />
      <CoreUserConfigContext.Provider
        value={{
          config: coreUserConfig,
          setConfig: setCoreUserConfig,
        }}
      >
        <Route path="/" exact={true} component={AppWithOktaAuth} />
      </CoreUserConfigContext.Provider>
    </Security>
  ) : (
    <></>
  );
}
export default AuthenticationModel;
