import {
  CommonFlagConfigurations,
  CommonLogsDataConfigurations,
  CoreUserConfig,
  GraphqlQueryParamater,
  PopupParameter,
  UserRoleDataConfigurations,
} from "../Interface/Interface";
/* SSO Authentication */
export const baseURL: string = window.location.origin;

/* API URLs */
export const baseApi = async () => {
  const res = (await fetch("/env/env.json")).json();
  return res.then((obj) => obj.API_URL);
};

export const graphqlAPIURL: string = "graphql";

export const inputFileMetadataQuery: GraphqlQueryParamater = {
  query: `query{InputFileMetadata {FileId FileName Generation Market Metric FileType ManualMetric FullOverrideFlagRequired}}`,
};

export const marketMetadataQuery: GraphqlQueryParamater = {
  query: "query{InputFileMetadata {Market ManualMetric}}",
};

export const inputFileProcessLogQuery: GraphqlQueryParamater = {
  query:
    "query{InputFileProcessLog{Status Comments RowsProcessed UploadedBy EndYearPeriod StartYearPeriod UploadedDateTime Market Id FileId Generation FileName Metric FullOverrideFlag}}",
};

export const inputFileSchemaQuery: GraphqlQueryParamater = {
  query: "query{InputFileSchema {ColumnName FileId}}",
};

export function checkYearPeriodQuery(fileID: string): GraphqlQueryParamater {
  return {
    query: `{CheckYearPeriod(FileId:${fileID})}`,
  };
}

export function checkAdminQuery(emailID: string): GraphqlQueryParamater {
  return {
    query: `query{getTpoUploadAdminUsers(EmailID:"${emailID}")}`,
  };
}

export function getUserRole(emailID: string): GraphqlQueryParamater {
  return {
    query: `query{GetUserRole(EMAIL_ID:"${emailID}" DEL_FLG:"N"){GPID EMAIL_ID BU_NM ROLE_TYPE}}`,
  };
}

export function getGPIDRole(gpid: string): GraphqlQueryParamater {
  return {
    query: `query{GetUserRole(GPID:"${gpid}" DEL_FLG:"N"){GPID EMAIL_ID BU_NM ROLE_TYPE}}`,
  };
}

export const createExcelFileURL: string = "downloadTemplate";
export const uploadExcelFileURL: string = "upload";
export const rowsProcessedExcelFileURL: string = "processedData";
export const fileCheckManual: string = "filecheckmanual";
export const fileUploadManual: string = "manualUpload";
export const manualUploadValidation: string = "manualUploadValidation";
export const sameFileCheck: string = "sameFileCheck";
export const uploadTemplate: string = "uploadTemplate";
/* Default Values */
export const defaultPopupParameter: PopupParameter = {
  isOpen: false,
  list: [],
  isSuccess: false,
};

export const defaultCoreUserConfig: CoreUserConfig = {
  isCoreUser: false,
  isAdmin: false,
  userEmail: "",
  gpid: "",
  userName: "",
  BU: [],
  token: "",
  tokenExpiration: -1,
};

export const defaultCommonFlags: CommonFlagConfigurations = {
  isRefreshPageIcon: true,
  isRefreshTableIcon: false,
  isShowUploadHistory: false,
  isShowRequestPage: false,
};

export const defaultLogsDataConfigurations: CommonLogsDataConfigurations = {
  logsFilterData: {
    user: [],
    generation: [],
    market: [],
    metric: [],
    FIleName: [],
    status: [],
    startYearPeriod: [],
    endYearPeriod: [],
    fullOverrideFlag: [],
  },
  processLogMetadata: [],
  tabName: "Dashboard",
};

export const defaultUserRoleData: UserRoleDataConfigurations = {
  emailID: "",
  gpid: "",
  buRoles: [],
};

/* Period Slicer Dropdown values */
export const timePeriodValues: string[] = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
];

/* Self Service File Names*/
export const profitFileName: string = "minp_tpo_dim_profit";
export const salesFileName: string = "minp_tpo_dim_sales";
export const wasteFileName: string = "minp_tpo_dim_waste";

export const filterKeys = {
  user: "user",
  generation: "generation",
  market: "market",
  metric: "metric",
  FIleName: "FIleName",
  status: "status",
  startYearPeriod: "startYearPeriod",
  endYearPeriod: "endYearPeriod",
};

export const selectAllText: string = "Select All";
export const dashboardTabName: string = "Dashboard";
export const selfServiceTabName: string = "Self Service";
export const adminTabName: string = "Admin";
export const requestPortalTabName: string = "Request Portal";
export const uploadTempplateTabName: string = "Upload Template";
export const userSuffix = " - Contractor {}";
export const validationSuccessMessage = "File validated successfully";
export const uploadSuccessMessage = "file uploaded successfully";
export const templateSuccessMessage = "Template uploaded successfully";

/* React Select */
export const filterstyle = {
  control: (provided: {}) => ({
    ...provided,
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#e0e0e0",
    },
    fontSize: "0.83vw",
    minHeight: "1.72vw",
    height: "1.72vw",
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    color: "#1a1a1a",
    fontSize: "0.83vw",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    color: "#1a1a1a",
    fontSize: "0.83vw",
  }),
  dropdownIndicator: (provided: {}) => ({
    ...provided,
    marginRight: "10px",
    color: "#1a1a1a",
    "&:hover": {
      color: "#1a1a1a",
    },
    padding: "2px 5px",
  }),
};
