import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import AuthenticationModel from "./AuthenticationModel";
import { BrowserRouter, withRouter } from "react-router-dom";
import "./index.css";

const AuthModelWithRouterAccess = withRouter(AuthenticationModel);
ReactDOM.render(
  <React.StrictMode>
    {window.location.pathname !== "/health" && (
      <BrowserRouter>
        <AuthModelWithRouterAccess />
      </BrowserRouter>
    )}
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
