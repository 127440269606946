import { Dispatch, SetStateAction } from "react";
import { PopupParameter } from "../Interface/Interface";
import icon_connection_failed from "../Images/icon-connection-failed.svg";
import icon_connection_success from "../Images/icon-connection-success.svg";
import Icon_close_circle from "./../Images/Icon-close-circle.svg";
import "./Popup.css";

function Popup(props: {
  popupParameters: PopupParameter;
  setPopupParameters: Dispatch<SetStateAction<PopupParameter>>;
}) {
  return (
    <div className="popup-bgblur">
      <div className="popup-layer">
        <div
          className="close-icon"
          onClick={() => {
            props.popupParameters.list = [];
            props.setPopupParameters({
              ...props.popupParameters,
              isOpen: false,
              list: [],
              isSuccess: false,
            });
          }}
        >
          <img src={Icon_close_circle} alt="" />
        </div>
        <div className="popup-title">
          {" "}
          <img
            className="status-icon"
            src={
              props.popupParameters.isSuccess
                ? icon_connection_success
                : icon_connection_failed
            }
            alt=""
          />
          <label className="status-text">
            {props.popupParameters.isSuccess
              ? "File Upload Success"
              : "File Upload Failed"}
          </label>
        </div>
        <div className="upload-status">
          {props.popupParameters.isSuccess ||
          props.popupParameters.list.length === 1 ? (
            <div className="single-message">
              {props.popupParameters.list[0]}
            </div>
          ) : (
            <ol>
              {" "}
              {props.popupParameters.list.map((err: string) => {
                return <li className="messages">{err}</li>;
              })}{" "}
            </ol>
          )}
        </div>
      </div>
    </div>
  );
}
export default Popup;
