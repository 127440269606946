import { OktaAuth } from "@okta/okta-auth-js";

export const acquireToken = async () => {
  const baseURL: string = window.location.origin;
  let oktaConfig = {
    oidc: {
      clientId: "",
      issuer: "",
      redirectUri: baseURL + "/login/callback",
      scopes: ["openid", "profile", "email"],
      pkce: true,
    },
  };
  await fetch("/env/env.json")
    .then((obj) => obj.json())
    .then((data) => {
      oktaConfig = {
        oidc: {
          clientId: data.Client_Id,
          issuer: data.Issuer,
          redirectUri: baseURL + "/login/callback",
          scopes: ["openid", "profile", "email"],
          pkce: true,
        },
      };
    });
  const oktaAuth = new OktaAuth(oktaConfig.oidc);
  try {
    const accessToken: any = await oktaAuth.tokenManager.get("accessToken");

    if (accessToken) {
      const expirationTime = accessToken.expiresAt;
      const currentTime = Date.now() / 1000;

      if (expirationTime && expirationTime > currentTime) {
        // Token is still valid, no need to renew
        return accessToken.accessToken;
      }

      // Token is expired, renew it
      const renewToken = await oktaAuth.token.renewTokens();

      // Save the new access token to the token manager
      await oktaAuth.tokenManager.setTokens(renewToken);
      return renewToken.accessToken?.accessToken;
    } else {
      // Token not found, user needs to authenticate
      console.log("Access token not found");
      // Redirect to login or handle the authentication flow
    }
  } catch (error) {
    console.error("Error renewing access token:", error);
    // Handle error, e.g., redirect to login or display error message
  }
};
