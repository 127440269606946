import React, { useContext, useEffect, useRef, useState } from "react";
import { CoreUserConfigContext } from "../ContextAPI/ContextAPI";
import pepsico_logo from "../Images/pepsico-logo.svg";
import "./Header.css";

function Header(props: { logoutFunction: () => void }) {
  const coreUserConfig = useContext(CoreUserConfigContext);
  const [logoutPopUp, setLogoutPopup] = useState(false);
  const [userDetails, setUserDetails] = useState({ photo: "" });
  const webappFavicon = "";

  const favicon = document.getElementById(
    "favicon"
  ) as HTMLAnchorElement | null;
  if (favicon) favicon.href = webappFavicon;

  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLogoutPopup(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <div id="header" className="header-sticky">
        <div id="header-logo">
          <img src={pepsico_logo} id="pepsico-logo" alt="PepsiCo Logo" />
        </div>
        <div id="header-title">TPO Manual Upload</div>
        <div
          id="user-icon"
          onClick={() => setLogoutPopup(true)}
          tabIndex={1}
          title={coreUserConfig.config.userName}
        >
          {userDetails.photo === "" ? (
            <div id="profile-name">
              {coreUserConfig.config.userName.substring(0, 1)}
            </div>
          ) : (
            <img src={userDetails.photo} id="profile-pic" alt="" />
          )}
        </div>
      </div>
      {logoutPopUp ? (
        <div id="profile-popup" ref={wrapperRef}>
          <div id="user-name" className="profile-popup-details">
            {coreUserConfig.config.userName}
          </div>
          <div id="user-email" className="profile-popup-details">
            {coreUserConfig.config.userEmail}
          </div>
          <div
            id="logout-button"
            className="profile-popup-details"
            onClick={props.logoutFunction}
            tabIndex={1}
            title="Logout"
          >
            Logout
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Header;
