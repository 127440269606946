import axios, { AxiosRequestConfig } from "axios";
import {
  checkAdminQuery,
  getGPIDRole,
  getUserRole,
  graphqlAPIURL,
  selectAllText,
  marketMetadataQuery,
  timePeriodValues,
  fileUploadManual,
  createExcelFileURL,
  profitFileName,
  salesFileName,
  wasteFileName,
  manualUploadValidation,
  validationSuccessMessage,
  uploadSuccessMessage,
  inputFileProcessLogQuery,
  sameFileCheck,
  baseApi,
  defaultPopupParameter,
  uploadTemplate,
  templateSuccessMessage,
} from "../Constants/Constants";
import {
  BURoleConfig,
  DictionaryCollection,
  GraphqlQueryParamater,
  InputFileMetaData,
  InputFileProcessLogMetaData,
  LogTableFilterConfigurations,
  PopupParameter,
  RequestStatusConfigurations,
  RoleRequestConfigurations,
  SearchRoleConfigurations,
  SelfServiceDataConfiguration,
  SelfServiceDropdownConfigurations,
  SelfServiceUploadStatus,
  UserRoleDataConfigurations,
} from "../Interface/Interface";
import { acquireToken } from "../AxiosInstance/AxiosInstance";
import { Dispatch, SetStateAction } from "react";

export function getCurrentTimeStamp(): string {
  const currentDateTime: Date = new Date(Date.now());
  const formattedDate = `${currentDateTime.getUTCFullYear()}-${String(
    currentDateTime.getUTCMonth() + 1
  ).padStart(2, "0")}-${String(currentDateTime.getUTCDate()).padStart(
    2,
    "0"
  )} ${String(currentDateTime.getUTCHours()).padStart(2, "0")}:${String(
    currentDateTime.getUTCMinutes()
  ).padStart(2, "0")}:${String(currentDateTime.getUTCSeconds()).padStart(
    2,
    "0"
  )}`;
  return formattedDate;
}

export function generateMarketValues(
  coreUserMarkets: string[],
  actualMarkets: string[]
): string[] {
  let marketValues: string[] = [];
  marketValues = actualMarkets.filter(
    (market: string) => coreUserMarkets.indexOf(market) !== -1
  );
  return marketValues;
}

export function checkFileEnableFlag(filename: string): boolean {
  let flag = true;
  if (filename !== "") {
    flag = false;
  }
  return flag;
}

export function generateUniqueMarketList(
  fileMetaData: InputFileMetaData[]
): string[] {
  let marketValue: string[] = [];

  if (fileMetaData.length > 0) {
    marketValue = fileMetaData
      .map((item: InputFileMetaData) => item.Market)
      .filter((value, index, self) => self.indexOf(value) === index);
    marketValue.sort();
  }

  return marketValue;
}

export function slicerClassName(selectedValue: string) {
  return selectedValue !== "" ? false : true;
}

export function validateFileName(
  selectedFileName: string,
  uploadedFileName?: string
) {
  if (
    uploadedFileName === selectedFileName + ".xlsx" ||
    uploadedFileName === "No file chosen"
  ) {
    return false;
  } else {
    return true;
  }
}

export function filterValueClassName(selectedValues: string[], value: string) {
  let index: number = -1;
  let selectAllIndex: number = -1;
  if (selectedValues.length > 0) {
    index = selectedValues.indexOf(value);
    selectAllIndex = selectedValues.indexOf(selectAllText);
  }
  let className = "";
  if (index !== -1 || selectAllIndex !== -1) {
    className = "selected-value";
  }
  return className;
}
export function loginUser(userName: string) {
  let name: string = userName;
  const indexContractor: number = userName.indexOf(" - Contractor");
  if (indexContractor !== -1) {
    userName = userName.substring(0, indexContractor);
  }
  const indexPep: number = userName.indexOf(" {");
  if (indexPep !== -1) {
    userName = userName.substring(0, indexPep);
  }
  const indexComma: number = userName.indexOf(",");
  if (indexComma !== -1) {
    const [lastname, firstname] = userName.split(", ");
    name = `${firstname} ${lastname}`;
  } else {
    name = userName;
  }
  return name;
}

export function updateSlicerList(
  actualValues: string[],
  selectedValues: string[],
  value: string
): string[] {
  let updatedList: string[] = selectedValues;
  const index: number = selectedValues.indexOf(value);
  let selectAllIndex: number = -1;
  if (selectedValues.length > 0) {
    selectAllIndex = selectedValues.indexOf(selectAllText);
  }
  if (value === selectAllText && index === -1) {
    updatedList = actualValues.map((item: string) => item);
  } else if (value === selectAllText && index !== -1) {
    updatedList = [];
  } else {
    if (index === -1) {
      updatedList.push(value);
    } else if (selectAllIndex === -1) {
      updatedList.splice(index, 1);
    } else {
      updatedList.splice(index, 1);
      const updatedSelectAllIndex: number = updatedList.indexOf(selectAllText);
      updatedList.splice(updatedSelectAllIndex, 1);
    }
  }
  return updatedList;
}

export function generateDropdownTitle(values: string[]) {
  let titleValue = "";
  if (values.length === 0) {
    titleValue = "Select Market";
  } else if (values.length === 1) {
    titleValue = values[0];
  } else {
    titleValue = values[0] + " + " + (values.length - 1);
  }
  return titleValue;
}

export function checkRequestButtonState(
  requestObject: SearchRoleConfigurations
): boolean {
  return requestObject.buNames.length > 0 ? false : true;
}

export function checkGPIDField(gpid: string) {
  return gpid === "" ? false : true;
}

export function generateYearDropdownValues(): string[] {
  let yearCollection: string[] = [];
  const currentDate: Date = new Date();
  const currentYear: number = currentDate.getFullYear();
  for (
    let iterator: number = currentYear - 2;
    iterator <= currentYear + 2;
    iterator++
  ) {
    yearCollection.push(iterator.toString());
  }
  return yearCollection;
}

export function generateEndYear(
  dropdownValues: string[],
  startYear: string
): string[] {
  let yearValues: string[] = dropdownValues;
  if (startYear !== "") {
    yearValues = yearValues.filter(
      (year: string) => year.toString() >= startYear
    );
  }
  return yearValues;
}

export function generateEndPeriod(
  startYear: string,
  endYear: string,
  startPeriod: string
): string[] {
  let periodValues: string[] = timePeriodValues;
  if (startPeriod !== "" && startYear === endYear) {
    periodValues = periodValues.filter(
      (period: string) => period.toString() >= startPeriod
    );
  }
  return periodValues;
}

export function generateSelectDataset(values: string[]) {
  let optionSet: DictionaryCollection[] = [];
  for (let iterator = 0; iterator < values.length; iterator++) {
    optionSet.push({
      value: values[iterator],
      label: values[iterator],
    });
  }
  return optionSet;
}

export function generateHistoryTableFilterValues(
  logsMetaData: InputFileProcessLogMetaData[]
): LogTableFilterConfigurations {
  const filterValues: LogTableFilterConfigurations = {
    user: [selectAllText],
    generation: [selectAllText],
    market: [selectAllText],
    metric: [selectAllText],
    FIleName: [selectAllText],
    status: [selectAllText],
    startYearPeriod: [selectAllText],
    endYearPeriod: [selectAllText],
    fullOverrideFlag: [selectAllText],
  };
  const userValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.UploadedBy)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.user = filterValues.user.concat(userValues);
  const generationValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.Generation)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.generation = filterValues.generation.concat(generationValues);
  const marketValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.Market)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.market = filterValues.market.concat(marketValues);
  const metricValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.Metric)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.metric = filterValues.metric.concat(metricValues);
  const filenameValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.FileName)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.FIleName = filterValues.FIleName.concat(filenameValues);
  const statusValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.Status)
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.status = filterValues.status.concat(statusValues);
  const startYearValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.StartYearPeriod.toString())
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.startYearPeriod =
    filterValues.startYearPeriod.concat(startYearValues);
  const endYearValues: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) => item.EndYearPeriod.toString())
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.endYearPeriod = filterValues.endYearPeriod.concat(endYearValues);
  const fullOverrideFlags: string[] = logsMetaData
    .map((item: InputFileProcessLogMetaData) =>
      item.FullOverrideFlag.toString()
    )
    .filter((value, index, self) => self.indexOf(value) === index);
  filterValues.fullOverrideFlag =
    filterValues.fullOverrideFlag.concat(fullOverrideFlags);

  return filterValues;
}

function requestStatusFlag(message: string): boolean {
  let successIndex: number = message.indexOf("success");
  return successIndex === -1 ? false : true;
}

/* API Calls */
export async function fetchUserRoleData(searchText: string, isGPID: boolean) {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let queryParameter: GraphqlQueryParamater;
  if (isGPID) {
    queryParameter = getGPIDRole(searchText);
  } else {
    queryParameter = getUserRole(searchText);
  }
  let userRoleData: UserRoleDataConfigurations = {
    gpid: "",
    emailID: "",
    buRoles: [],
  };
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  await axios
    .post(baseApiUrl + graphqlAPIURL, queryParameter, config)
    .then((response: any) => {
      let userData = response.data.data["GetUserRole"];
      if (userData?.length > 0) {
        userRoleData.gpid = userData[0]["GPID"];
        userRoleData.emailID = userData[0]["EMAIL_ID"];
        let buRoleData: BURoleConfig[] = [];
        for (let iterator = 0; iterator < userData.length; iterator++) {
          buRoleData.push({
            buName: userData[iterator]["BU_NM"],
            roleType: userData[iterator]["ROLE_TYPE"],
          });
        }
        userRoleData.buRoles = buRoleData;
      }
    });
  return userRoleData;
}

export async function submitUserRoleRequest(
  requestObject: SearchRoleConfigurations
) {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let reqStatus: RequestStatusConfigurations[] = [];
  let buNamelength: number = requestObject.buNames.length;
  for (let iterator: number = 0; iterator < buNamelength; iterator++) {
    let queryParameter: GraphqlQueryParamater = {
      query: `mutation{
      updateTpoUploadAccessRequests(
          BUName: "${requestObject.buNames[iterator]}",
          GPID: "${requestObject.gpid}",
          EmailID: "${requestObject.emailID}",
          RoleType: "CORE_USER",
          CreatedDate: "${getCurrentTimeStamp()}",
          isApproved: "pending"
      )
    }`,
    };

    let config: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(baseApiUrl + graphqlAPIURL, queryParameter, config)
      .then((response: any) => {
        if (response.data.data["updateTpoUploadAccessRequests"] !== undefined) {
          reqStatus.push({
            buName: requestObject.buNames[iterator],
            message: response.data.data["updateTpoUploadAccessRequests"],
          });
        }
      });
  }
  return reqStatus;
}

export async function getUserRoleRequestDetails() {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let queryParameter: GraphqlQueryParamater = {
    query: `query{
        getTpoUploadAccessRequests {
            GPID
            EmailID
            BUName
            RoleType
            CreatedDate
            isApproved
        }}`,
  };

  let config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  let responseData: RoleRequestConfigurations[] = [];
  await axios
    .post(baseApiUrl + graphqlAPIURL, queryParameter, config)
    .then((response: any) => {
      if (response.data.data["getTpoUploadAccessRequests"] !== undefined) {
        let data = response.data.data["getTpoUploadAccessRequests"];
        let length = data.length;
        for (let iterator = 0; iterator < length; iterator++) {
          responseData.push({
            id: iterator.toString(),
            gpid: data[iterator]["GPID"],
            emailID: data[iterator]["EmailID"],
            buName: data[iterator]["BUName"],
            roleType: data[iterator]["RoleType"],
            status: data[iterator]["isApproved"],
            createdDate: data[iterator]["CreatedDate"],
            isSelectedForApproval: false,
            isSelectedForRejection: false,
          });
        }
      }
    });

  return responseData;
}

export async function checkIsAdmin(emailId: string) {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let queryParameter: GraphqlQueryParamater = checkAdminQuery(emailId);
  let flag = false;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  await axios
    .post(baseApiUrl + graphqlAPIURL, queryParameter, config)
    .then((response: any) => {
      flag = response.data.data["getTpoUploadAdminUsers"];
    });
  return flag;
}

export async function fetchAdminUserRoleData(emailId: string) {
  let userRoleData: UserRoleDataConfigurations = {
    gpid: "",
    emailID: "",
    buRoles: [],
  };
  let responseObject = {
    userRoleinformation: userRoleData,
    isAdmin: false,
  };
  await fetchUserRoleData(emailId, false).then((data) => {
    responseObject.userRoleinformation = data;
  });
  await checkIsAdmin(emailId).then((status) => {
    responseObject.isAdmin = status;
  });
  return responseObject;
}

export async function updateRequestStatus(
  requestObject: RoleRequestConfigurations[],
  isApproved: boolean,
  setRequestCount: React.Dispatch<
    React.SetStateAction<{ completed: number; total: number }>
  >
) {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let reqStatus: boolean[] = [];
  let requestLength: number = requestObject.length;
  setRequestCount({ completed: 0, total: requestLength });
  for (let iterator: number = 0; iterator < requestLength; iterator++) {
    let queryParameter: GraphqlQueryParamater = {
      query: `mutation{
        ASEC_IDM_TPO_USER_SCRTY(
          IDM_READ_DTM: "${getCurrentTimeStamp()}",
          DW_UPDT_DTM: "${getCurrentTimeStamp()}",
          ROLE_STS: "NULL",
          GPID: "${requestObject[iterator].gpid}",
          BU_NM: "${requestObject[iterator].buName}",
          USER_STS: "NULL",
          DEL_FLG: "N",
          ROLE_TYPE: "CORE_USER",
          EMAIL_ID: "${requestObject[iterator].emailID}",
          DW_CRTD_DTM: "${requestObject[iterator].createdDate}",
          isApproved: "${isApproved ? "approved" : "rejected"}"
        )
      }`,
    };
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(baseApiUrl + graphqlAPIURL, queryParameter, config)
      .then((response: any) => {
        if (
          response.data.data["ASEC_IDM_TPO_USER_SCRTY"] !== undefined &&
          requestStatusFlag(response.data.data["ASEC_IDM_TPO_USER_SCRTY"])
        ) {
          reqStatus.push(true);
        } else {
          reqStatus.push(false);
        }
      });
    setRequestCount({ completed: iterator + 1, total: requestLength });
  }
  return reqStatus;
}

export async function getMarketList() {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  let marketValue: string[] = [];
  let manualMarketValue: string[] = [];
  await axios
    .post(baseApiUrl + graphqlAPIURL, marketMetadataQuery, config)
    .then((response: any) => {
      let fileMetaData: InputFileMetaData[] =
        response.data.data["InputFileMetadata"];
      if (fileMetaData.length > 0) {
        marketValue = fileMetaData
          .map((item: InputFileMetaData) => item.Market)
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index
          )
          .sort((firstValue: string, secondValue: string) => {
            return firstValue > secondValue ? 1 : -1;
          });
        manualMarketValue = fileMetaData
          .filter((item: InputFileMetaData) => item.ManualMetric === "Y")
          .map((item: InputFileMetaData) => item.Market)
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index
          )
          .sort((firstValue: string, secondValue: string) => {
            return firstValue > secondValue ? 1 : -1;
          });
      }
    });
  return { marketValue: marketValue, manualMarketValue: manualMarketValue };
}

async function ValidateExcelFile(fileData: FormData): Promise<string[]> {
  let message: string[] = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "multipart/form-data",
    },
  };

  await axios
    .post(baseApiUrl + manualUploadValidation, fileData, config)
    .then((response) => {
      if (typeof response.data === "string") {
        message.push(response.data);
      } else {
        message = response.data;
      }
    })
    .catch((err) => {
      message.push(err);
    });

  return message;
}

async function UploadExcelFileURL(fileData: FormData): Promise<string[]> {
  let message: string[] = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "multipart/form-data",
    },
  };

  await axios
    .post(baseApiUrl + fileUploadManual, fileData, config)
    .then((response) => {
      if (typeof response.data === "string") {
        message.push(response.data);
      } else {
        message = response.data;
      }
    })
    .catch((err) => {
      message.push(err);
    });

  return message;
}

export async function UploadSelfServiceExcel(
  dataConfigurations: SelfServiceDataConfiguration,
  dropDownValues: SelfServiceDropdownConfigurations,
  username: string
): Promise<SelfServiceUploadStatus[]> {
  let messages: SelfServiceUploadStatus[] = [];

  if (dataConfigurations.isProfitExistingData != true) {
    let profitFile: any = dataConfigurations.profitFile;
    let profitFileData: FormData = new FormData();
    profitFileData.append("StartYear", dropDownValues.startYear.toString());
    profitFileData.append("StartPeriod", dropDownValues.startPeriod.toString());
    profitFileData.append("EndYear", dropDownValues.endYear.toString());
    profitFileData.append("EndPeriod", dropDownValues.endPeriod.toString());
    profitFileData.append("UserName", username);
    profitFileData.append("file", profitFile);
    profitFileData.append("FileId", dataConfigurations.profitFileId.toString());
    await UploadExcelFileURL(profitFileData).then((data) => {
      messages.push({
        fileName: profitFileName,
        message: data,
        isSuccessFlag:
          data[0].indexOf(uploadSuccessMessage) !== -1 ? true : false,
      });
    });
  }

  if (dataConfigurations.isSalesExistingData != true) {
    let salesFile: any = dataConfigurations.salesFile;
    let salesFileData = new FormData();
    salesFileData.append("StartYear", dropDownValues.startYear.toString());
    salesFileData.append("StartPeriod", dropDownValues.startPeriod.toString());
    salesFileData.append("EndYear", dropDownValues.endYear.toString());
    salesFileData.append("EndPeriod", dropDownValues.endPeriod.toString());
    salesFileData.append("UserName", username);
    salesFileData.append("file", salesFile);
    salesFileData.append("FileId", dataConfigurations.salesFileId.toString());
    await UploadExcelFileURL(salesFileData).then((data) => {
      messages.push({
        fileName: salesFileName,
        message: data,
        isSuccessFlag:
          data[0].indexOf(uploadSuccessMessage) !== -1 ? true : false,
      });
    });
  }

  if (dataConfigurations.isWasteExistingData != true) {
    let wasteFile: any = dataConfigurations.wasteFile;
    let wasteFileData: FormData = new FormData();
    wasteFileData.append("StartYear", dropDownValues.startYear.toString());
    wasteFileData.append("StartPeriod", dropDownValues.startPeriod.toString());
    wasteFileData.append("EndYear", dropDownValues.endYear.toString());
    wasteFileData.append("EndPeriod", dropDownValues.endPeriod.toString());
    wasteFileData.append("UserName", username);
    wasteFileData.append("file", wasteFile);
    wasteFileData.append("FileId", dataConfigurations.wasteFileId.toString());
    await UploadExcelFileURL(wasteFileData).then((data) => {
      messages.push({
        fileName: wasteFileName,
        message: data,
        isSuccessFlag:
          data[0].indexOf(uploadSuccessMessage) !== -1 ? true : false,
      });
    });
  }

  return messages;
}

export async function SelfServiceExcelValidation(
  file: any,
  fileID: number,
  dropDownValues: SelfServiceDropdownConfigurations
): Promise<SelfServiceUploadStatus> {
  let messages: SelfServiceUploadStatus = {
    fileName: file.name,
    message: [],
    isSuccessFlag: false,
  };

  let fileData: FormData = new FormData();
  fileData.append("file", file);
  fileData.append("FileId", fileID.toString());
  fileData.append("StartYear", dropDownValues.startYear.toString());
  fileData.append("StartPeriod", dropDownValues.startPeriod.toString());
  fileData.append("EndYear", dropDownValues.endYear.toString());
  fileData.append("EndPeriod", dropDownValues.endPeriod.toString());
  await ValidateExcelFile(fileData).then((data) => {
    if (data.length > 0 && data[0].indexOf(validationSuccessMessage) === -1) {
      messages = {
        fileName: file.name,
        message: data,
        isSuccessFlag: false,
      };
    } else {
      messages = {
        fileName: file.name,
        message: [validationSuccessMessage],
        isSuccessFlag: true,
      };
    }
  });

  return messages;
}

export async function sameFileValidation() {
  let errorMessage: SelfServiceUploadStatus[] = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "application/json",
    },
  };
  await axios
    .post(baseApiUrl + sameFileCheck, { FileName: profitFileName }, config)
    .then((response) => {
      if (response.data !== "Continue") {
        errorMessage.push({
          fileName: profitFileName,
          message: [response.data],
          isSuccessFlag: false,
        });
      }
    });
  return errorMessage;
}

export async function DownloadExcelTemplateFile(
  fileId: number,
  fileName: string
) {
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "application/json",
    },
    responseType: "blob",
  };
  let templatedata = {
    FileId: fileId,
  };
  await axios
    .post(baseApiUrl + createExcelFileURL, templatedata, config)
    .then((response) => {
      let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName.toLowerCase() + `.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.remove();
    });
}

export async function RefreshProcessingLogs() {
  let processLogsData: InputFileProcessLogMetaData[] = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  await axios
    .post(baseApiUrl + graphqlAPIURL, inputFileProcessLogQuery, config)
    .then((response: any) => {
      let logsMetaData: InputFileProcessLogMetaData[] =
        response.data.data["InputFileProcessLog"];
      if (logsMetaData && logsMetaData.length > 0) {
        processLogsData = logsMetaData;
      }
    });
  return processLogsData;
}

export async function uploadExcelFiletemplate(
  file: any,
  fileid: number,
  popupParameters: PopupParameter,
  setPopupParameters: Dispatch<SetStateAction<PopupParameter>>
) {
  let statusData: PopupParameter = defaultPopupParameter;
  statusData.list = [];
  const accessToken: string = await acquireToken();
  const baseApiUrl: string = await baseApi();
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "content-type": "multipart/form-data",
    },
  };
  let filedata = new FormData();
  filedata.append("file", file);
  filedata.append("FileId", fileid.toString());
  await axios
    .post(baseApiUrl + uploadTemplate, filedata, config)
    .then((response) => {
      statusData.isOpen = true;
      if (typeof response.data === "string") {
        statusData.list.push(response.data);
        statusData.isSuccess =
          response.data === templateSuccessMessage ? true : false;
      } else {
        let length: number = response.data.length;
        for (let iterator: number = 0; iterator < length; iterator++) {
          statusData.list.push(response.data[iterator]);
        }
        statusData.isSuccess = false;
      }
      setPopupParameters({
        ...popupParameters,
        isOpen: true,
        list: statusData.list,
        isSuccess: statusData.isSuccess,
      });
    })
    .catch((err) => {
      console.log(err);
      statusData.isOpen = true;
      statusData.isSuccess = false;
      setPopupParameters({
        ...popupParameters,
        isOpen: true,
        list: [],
        isSuccess: false,
      });
    });
}
