import React, { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  CoreUserConfigContext,
  CommonFlagConfigContext,
  CommonLogsDataConfigContext,
} from "./ContextAPI/ContextAPI";
import {
  defaultCommonFlags,
  defaultLogsDataConfigurations,
} from "./Constants/Constants";
import {
  CommonFlagConfigurations,
  CommonLogsDataConfigurations,
} from "./Interface/Interface";
import Header from "./Header/Header";
import Home from "./Home/Home";
import "./App.css";

function App() {
  const { authState, oktaAuth } = useOktaAuth();
  const coreUserConfig = useContext(CoreUserConfigContext);
  const [authentionFlag, setAuthentionFlag] = useState(false);
  const [commonFlags, setCommonFlags] =
    useState<CommonFlagConfigurations>(defaultCommonFlags);
  const [logsDataConfig, setLogsDataConfig] =
    useState<CommonLogsDataConfigurations>(defaultLogsDataConfigurations);

  const fetchUserDetails = async () => {
    try {
      const user = await oktaAuth.getUser();
      coreUserConfig.setConfig({
        ...coreUserConfig.config,
        userEmail: user.email ?? "",
        userName: user.FirstName + " " + user.LastName,
        gpid: user.gpid.toString(),
        token: authState?.accessToken?.accessToken ?? "",
        tokenExpiration: authState?.accessToken?.expiresAt ?? -1,
      });
    } catch (error) {
      // Handle any errors
      console.error("Error fetching user details:", error);
    }
  };
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const triggerLogout = async () => {
    await oktaAuth.signOut({
      idToken: authState?.idToken
    });
  };
  useEffect(() => {
    if (authState?.isAuthenticated) {
      setAuthentionFlag(true);
      fetchUserDetails();
    } else {
      triggerLogin();
    }
  }, [authState]);
  return (
    <div className="App">
      {authentionFlag && (
        <>
          <Header logoutFunction={triggerLogout} />
          <CommonFlagConfigContext.Provider
            value={{
              flags: commonFlags,
              setFlags: setCommonFlags,
            }}
          >
            <CommonLogsDataConfigContext.Provider
              value={{
                data: logsDataConfig,
                setData: setLogsDataConfig,
              }}
            >
              <Home />
            </CommonLogsDataConfigContext.Provider>
          </CommonFlagConfigContext.Provider>
        </>
      )}
    </div>
  );
}

export default App;
