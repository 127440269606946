import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import axios, { AxiosRequestConfig } from "axios";
import {
  InputFileMetaData,
  InputFileProcessLogMetaData,
  LogTableFilterConfigurations,
  SelfServiceDataConfiguration,
  SelfServiceDropdownConfigurations,
  SelfServiceUploadStatus,
} from "../Interface/Interface";
import {
  timePeriodValues,
  filterstyle,
  fileCheckManual,
  profitFileName,
  salesFileName,
  wasteFileName,
  selfServiceTabName,
  baseApi,
} from "./../Constants/Constants";
import {
  DownloadExcelTemplateFile,
  RefreshProcessingLogs,
  SelfServiceExcelValidation,
  UploadSelfServiceExcel,
  generateEndPeriod,
  generateEndYear,
  generateHistoryTableFilterValues,
  generateSelectDataset,
  generateYearDropdownValues,
  validateFileName,
  sameFileValidation,
} from "../CommonFunctions/CommonFunctions";
import {
  CommonFlagConfigContext,
  CommonLogsDataConfigContext,
  CoreUserConfigContext,
  FileMetadataContext,
} from "../ContextAPI/ContextAPI";
import SelfServicePopup from "../SelfServicePopup/SelfServicePopup";
import icon_refresh from "../Images/icon-refresh.svg";
import { acquireToken } from "../AxiosInstance/AxiosInstance";
import "./SelfService.css";

function isShowFileAttachemntSection(
  values: SelfServiceDropdownConfigurations
) {
  return values.market !== "" &&
    values.startYear !== "" &&
    values.startPeriod !== "" &&
    values.endYear !== "" &&
    values.endPeriod !== ""
    ? ""
    : "hide";
}

function isFileEnableFlag(flagValue?: boolean) {
  return flagValue !== false ? true : false;
}

function isFileUploadSelectedFlag(flagValue?: boolean) {
  return !flagValue && flagValue !== undefined ? true : false;
}

function fileEnabledClassName(flagValue?: boolean): string {
  let className = "disable-input";
  if (flagValue === false) {
    className = "pointer";
  }
  return className;
}

function fetchFileId(
  fileMetadata: InputFileMetaData[],
  selectedMarket: string
) {
  let profitFileId = -1;
  let salesFileId = -1;
  let wasteFileId = -1;
  profitFileId =
    fileMetadata.filter(
      (metadata: InputFileMetaData) =>
        metadata.FileName === profitFileName &&
        metadata.Market === selectedMarket
    )[0]?.FileId ?? -1;
  salesFileId =
    fileMetadata.filter(
      (metadata: InputFileMetaData) =>
        metadata.FileName === salesFileName &&
        metadata.Market === selectedMarket
    )[0]?.FileId ?? -1;
  wasteFileId =
    fileMetadata.filter(
      (metadata: InputFileMetaData) =>
        metadata.FileName === wasteFileName &&
        metadata.Market === selectedMarket
    )[0]?.FileId ?? -1;
  return {
    profitFileId: profitFileId,
    salesFileId: salesFileId,
    wasteFileId: wasteFileId,
  };
}

function isEnableFileUpload(
  values: SelfServiceDataConfiguration,
  isUpload: boolean
) {
  if (isUpload) {
    if (
      values.isProfitExistingData &&
      values.isSalesExistingData &&
      values.isWasteExistingData
    )
      return true;
    return (values.isProfitExistingData ||
      (!values.isProfitExistingData &&
        values.profitFile !== null &&
        !validateFileName(profitFileName, values.profitFile.name) &&
        values.isProfitFileValidated)) &&
      (values.isSalesExistingData ||
        (!values.isSalesExistingData &&
          values.salesFile !== null &&
          !validateFileName(salesFileName, values.salesFile.name) &&
          values.isSalesFileValidated)) &&
      (values.isWasteExistingData ||
        (!values.isWasteExistingData &&
          values.wasteFile !== null &&
          !validateFileName(wasteFileName, values.wasteFile.name) &&
          values.isWasteFileValidated))
      ? false
      : true;
  } else {
    return values.isProfitExistingData !== false ||
      values.profitFileName !== "No file chosen" ||
      values.profitFile !== null ||
      values.isProfitFileValidated !== false ||
      values.isSalesExistingData !== false ||
      values.salesFileName !== "No file chosen" ||
      values.salesFile !== null ||
      values.isSalesFileValidated !== false ||
      values.isWasteExistingData !== false ||
      values.wasteFileName !== "No file chosen" ||
      values.wasteFile !== null ||
      values.isWasteFileValidated !== false
      ? false
      : true;
  }
}

function SelfService(props: { allMarketList: string[] }) {
  const coreUserConfig = useContext(CoreUserConfigContext);
  const commonFlags = useContext(CommonFlagConfigContext);
  const fileMetaDataContext = useContext(FileMetadataContext);
  const logsDataConfigs = useContext(CommonLogsDataConfigContext);
  const [existingDataFlag, setExistingDataFlag] = useState({
    profit: false,
    sales: false,
    waste: false,
  });
  const [messages, setMessages] = useState<SelfServiceUploadStatus[]>([]);
  const [isValidationMessage, setIsValidationMessage] =
    useState<boolean>(false);
  const [showLoaderFlag, setLoaderFlag] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] =
    useState<SelfServiceDataConfiguration>({
      isProfitExistingData: false,
      profitFileName: "No file chosen",
      profitFile: null,
      profitFileId: -1,
      isProfitFileValidated: false,
      isSalesExistingData: false,
      salesFileName: "No file chosen",
      salesFile: null,
      salesFileId: -1,
      isSalesFileValidated: false,
      isWasteExistingData: false,
      wasteFileName: "No file chosen",
      wasteFile: null,
      wasteFileId: -1,
      isWasteFileValidated: false,
    });
  const [dropdownValues, setDropDownValues] =
    useState<SelfServiceDropdownConfigurations>({
      market: "",
      startYear: "",
      startPeriod: "",
      endYear: "",
      endPeriod: "",
    });
  const [recentFileName, setRecentFileName] = useState("");
  const selfServiceMarkets = props.allMarketList;

  const checkExistingFile = async () => {
    const accessToken: string = await acquireToken();
    const baseApiUrl: string = await baseApi();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "content-type": "multipart/form-data",
      },
    };
    let payload = new FormData();
    payload.append("StartYear", dropdownValues.startYear);
    payload.append("StartPeriod", dropdownValues.startPeriod);
    payload.append("EndPeriod", dropdownValues.endPeriod);
    payload.append("EndYear", dropdownValues.endYear);
    payload.append("Market", dropdownValues.market);
    await axios
      .post(baseApiUrl + fileCheckManual, payload, config)
      .then((response) => {
        setExistingDataFlag({
          profit: response.data.Profit,
          sales: response.data.Sales,
          waste: response.data.Waste,
        });
      });
  };

  useEffect(() => {
    if (isShowFileAttachemntSection(dropdownValues) === "") {
      checkExistingFile();
    }
  }, [dropdownValues]);

  useEffect(() => {
    const fileIds = fetchFileId(
      fileMetaDataContext.data,
      dropdownValues.market
    );
    if (
      uploadedFileName.profitFileId === -1 ||
      uploadedFileName.salesFileId === -1 ||
      uploadedFileName.wasteFileId === -1
    ) {
      setUploadedFileName({
        ...uploadedFileName,
        profitFileId: fileIds.profitFileId,
        salesFileId: fileIds.salesFileId,
        wasteFileId: fileIds.wasteFileId,
      });
    }
  }, [dropdownValues.market]);

  let yearDropdownValues: string[] = [];
  let endYearDropDown: string[] = [];
  let periodDropDownValues: string[] = [];
  let endPeriodValues: string[] = [];

  yearDropdownValues = generateYearDropdownValues();
  endYearDropDown = generateEndYear(
    yearDropdownValues,
    dropdownValues.startYear
  );
  periodDropDownValues = timePeriodValues;
  endPeriodValues = generateEndPeriod(
    dropdownValues.startYear,
    dropdownValues.endYear,
    dropdownValues.startPeriod
  );

  const salesFileInputRef = useRef(null);
  const profitFileInputRef = useRef(null);
  const wasteFileInputRef = useRef(null);

  const resetFileUploadState = () => {
    setUploadedFileName({
      ...uploadedFileName,
      isProfitExistingData: false,
      profitFileName: "No file chosen",
      profitFile: null,
      isProfitFileValidated: false,
      isSalesExistingData: false,
      salesFileName: "No file chosen",
      salesFile: null,
      isSalesFileValidated: false,
      isWasteExistingData: false,
      wasteFileName: "No file chosen",
      wasteFile: null,
      isWasteFileValidated: false,
    });
  };

  const uploadFileAttachemnts = () => {
    setLoaderFlag(true);
    sameFileValidation()
      .then((resp) => {
        if (resp.length === 0) {
          return UploadSelfServiceExcel(
            uploadedFileName,
            dropdownValues,
            coreUserConfig.config.userEmail
          );
        } else {
          setIsValidationMessage(true);
          setMessages(resp);
        }
      })
      .then((response) => {
        if (response) {
          setIsValidationMessage(false);
          setMessages(response);
          resetFileUploadState();
        }
      })
      .finally(() => {
        setLoaderFlag(false);
      });
  };

  const refreshFileProcessLog = () => {
    RefreshProcessingLogs().then((response: InputFileProcessLogMetaData[]) => {
      if (response.length > 0) {
        const manualMarketFileIds = fileMetaDataContext.data
          .filter(
            (fileData: InputFileMetaData) => fileData.ManualMetric === "Y"
          )
          .map((fileData: InputFileMetaData) => fileData.FileId);
        const manualMarketProcessLogs: InputFileProcessLogMetaData[] =
          response.filter((metaData: InputFileProcessLogMetaData) => {
            return manualMarketFileIds.indexOf(metaData.FileId) !== -1;
          });
        const historyTableFilterData: LogTableFilterConfigurations =
          generateHistoryTableFilterValues(manualMarketProcessLogs);
        logsDataConfigs.setData({
          ...logsDataConfigs.data,
          processLogMetadata: manualMarketProcessLogs,
          logsFilterData: historyTableFilterData,
          tabName: selfServiceTabName,
        });
        commonFlags.setFlags({
          ...commonFlags.flags,
          isRefreshTableIcon: false,
          isShowUploadHistory: true,
          isShowRequestPage: false,
        });
      }
    });
  };
  return (
    <>
      <div className="selection-text">
        <label className="selection-download-text">
          Please select Market, Start Year, Start Period, End Year and End
          Period to download the template file/upload the relevant file
        </label>
      </div>
      <div className="selfservice-selection">
        <div className="dropdown-row-ss">
          <div className="market-section dropdown-list dropdown-col1">
            <label className="dropdown-title">Market</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(selfServiceMarkets)}
              isSearchable={false}
              placeholder="Select Market"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-startyear"}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.market
                ) {
                  setDropDownValues({
                    ...dropdownValues,
                    market: selectedOption.value,
                  });
                  resetFileUploadState();
                }
              }}
            />
          </div>

          <div className="start-year-section dropdown-list dropdown-col1">
            <label className="dropdown-title">Start Year </label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(yearDropdownValues)}
              isSearchable={false}
              isDisabled={dropdownValues.market === ""}
              placeholder="Select Year"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-startyear"}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.startYear
                ) {
                  setDropDownValues({
                    ...dropdownValues,
                    startYear: selectedOption.value,
                    endYear: "",
                    endPeriod: "",
                  });
                  resetFileUploadState();
                }
              }}
            />
          </div>
          <div className="start-period-section dropdown-list dropdown-col2">
            <label className="dropdown-title">Start Period</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(periodDropDownValues)}
              isSearchable={false}
              isDisabled={dropdownValues.startYear === ""}
              placeholder="Select Period"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-startperiod"}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.startPeriod
                ) {
                  setDropDownValues({
                    ...dropdownValues,
                    startPeriod: selectedOption.value,
                    endPeriod: "",
                  });
                  resetFileUploadState();
                }
              }}
            />
          </div>
          <div className="end-year-section dropdown-list dropdown-col3">
            <label className="dropdown-title">End Year</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(endYearDropDown)}
              isSearchable={false}
              isDisabled={dropdownValues.startPeriod === ""}
              placeholder="Select Year"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={"react-select-year" + dropdownValues.startYear}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.endYear
                ) {
                  let endYear = selectedOption.value;
                  let endPeriod =
                    dropdownValues.startYear === selectedOption.value &&
                    dropdownValues.endPeriod < dropdownValues.startPeriod
                      ? ""
                      : dropdownValues.endPeriod;
                  setDropDownValues({
                    ...dropdownValues,
                    endYear: endYear,
                    endPeriod: endPeriod,
                  });
                  resetFileUploadState();
                }
              }}
            />
          </div>
          <div className="end-period-section dropdown-list dropdown-col4">
            <label className="dropdown-title">End Period</label>
            <Select
              classNamePrefix="react-select"
              options={generateSelectDataset(endPeriodValues)}
              isSearchable={false}
              isDisabled={dropdownValues.endYear === ""}
              placeholder="Select Period"
              isClearable={false}
              hideSelectedOptions={false}
              styles={filterstyle}
              key={
                "react-select-start-period" +
                dropdownValues.startPeriod +
                dropdownValues.endYear
              }
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  selectedOption.value !== dropdownValues.endPeriod
                ) {
                  setDropDownValues({
                    ...dropdownValues,
                    endPeriod: selectedOption.value,
                  });
                  resetFileUploadState();
                }
              }}
            />
          </div>
        </div>
        <div className="template-selfservice">
          <div className={"download-template-ss"}>
            <div className="upload-parent-section file-upload-section">
              <div
                className={
                  "upload-section-ss " +
                  isShowFileAttachemntSection(dropdownValues)
                }
              >
                <div className="upload-attachment-wrap">
                  <label className="dropdown-title">File Attachments</label>
                  <div className="attachment-row">
                    <div className="attachment-title">Profit: </div>
                    <input
                      type="radio"
                      value="existingData"
                      name="profit-file"
                      checked={uploadedFileName.isProfitExistingData}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isProfitExistingData: true,
                          profitFile: null,
                          profitFileName: "No file chosen",
                        });
                      }}
                      disabled={!existingDataFlag.profit}
                    />
                    <label className="attachment-value">
                      Reuse Existing Data
                    </label>
                    <input
                      type="radio"
                      className="attachment-value"
                      value="existingData"
                      name="profit-file"
                      checked={isFileUploadSelectedFlag(
                        uploadedFileName.isProfitExistingData
                      )}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isProfitExistingData: false,
                        });
                      }}
                    />
                    <label className="attachment-value">Upload New</label>
                    <div className="custom-upload">
                      <div className="selected-file-label">
                        {uploadedFileName.profitFileName}
                      </div>
                      <label
                        className={
                          "upload-btn " +
                          fileEnabledClassName(
                            uploadedFileName.isProfitExistingData
                          )
                        }
                        htmlFor="uploadProfit"
                      >
                        <label className="upload-text" htmlFor="uploadProfit">
                          Choose File
                        </label>
                      </label>
                    </div>
                    <input
                      id="uploadProfit"
                      type="file"
                      disabled={isFileEnableFlag(
                        uploadedFileName.isProfitExistingData
                      )}
                      onChange={(event) => {
                        let inputFile = event.target.files
                          ? event.target.files[0]
                          : null;
                        if (inputFile) {
                          setRecentFileName(profitFileName);
                          setLoaderFlag(true);
                          SelfServiceExcelValidation(
                            inputFile,
                            uploadedFileName.profitFileId,
                            dropdownValues
                          ).then((response) => {
                            setLoaderFlag(false);

                            let status: SelfServiceUploadStatus[] = [];
                            status.push(response);
                            setIsValidationMessage(true);
                            setMessages(status);
                            if (
                              response.message[0].indexOf(
                                "File validated successfully"
                              ) === -1
                            ) {
                              setUploadedFileName({
                                ...uploadedFileName,
                                profitFileName: "No file chosen",
                                profitFile: null,
                              });
                            } else {
                              setUploadedFileName({
                                ...uploadedFileName,
                                profitFileName: inputFile?.name ?? "",
                                profitFile: inputFile,
                                isProfitFileValidated: true,
                              });
                            }
                          });
                          event.target.value = "";
                        }
                      }}
                      multiple={false}
                      ref={profitFileInputRef}
                    />
                    <div className="download-btn-ss">
                      <button
                        className="template-ss-btn"
                        onClick={() => {
                          DownloadExcelTemplateFile(
                            uploadedFileName.profitFileId,
                            profitFileName
                          );
                        }}
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  <div className="attachment-row">
                    <div className="attachment-title">Sales: </div>
                    <input
                      type="radio"
                      value="existingData"
                      name="sales-file"
                      checked={uploadedFileName.isSalesExistingData}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isSalesExistingData: true,
                          salesFile: null,
                          salesFileName: "No file chosen",
                        });
                      }}
                      disabled={!existingDataFlag.sales}
                    />
                    <label className="attachment-value">
                      Reuse Existing Data
                    </label>
                    <input
                      type="radio"
                      className="attachment-value"
                      value="existingData"
                      name="sales-file"
                      checked={isFileUploadSelectedFlag(
                        uploadedFileName.isSalesExistingData
                      )}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isSalesExistingData: false,
                        });
                      }}
                    />
                    <label className="attachment-value">Upload New</label>
                    <div className="custom-upload">
                      <div className="selected-file-label">
                        {uploadedFileName.salesFileName}
                      </div>
                      <label
                        className={
                          "upload-btn " +
                          fileEnabledClassName(
                            uploadedFileName.isSalesExistingData
                          )
                        }
                        htmlFor="uploadSales"
                      >
                        <label className="upload-text" htmlFor="uploadSales">
                          Choose File
                        </label>
                      </label>
                    </div>
                    <input
                      id="uploadSales"
                      type="file"
                      disabled={isFileEnableFlag(
                        uploadedFileName.isSalesExistingData
                      )}
                      onChange={(event) => {
                        let inputFile = event.target.files
                          ? event.target.files[0]
                          : null;
                        if (inputFile) {
                          setLoaderFlag(true);
                          setRecentFileName(salesFileName);
                          SelfServiceExcelValidation(
                            inputFile,
                            uploadedFileName.salesFileId,
                            dropdownValues
                          ).then((response) => {
                            setLoaderFlag(false);
                            let status: SelfServiceUploadStatus[] = [];
                            status.push(response);
                            setIsValidationMessage(true);
                            setMessages(status);
                            if (
                              response.message[0].indexOf(
                                "File validated successfully"
                              ) === -1
                            ) {
                              setUploadedFileName({
                                ...uploadedFileName,
                                salesFileName: "No file chosen",
                                salesFile: null,
                              });
                            } else {
                              setUploadedFileName({
                                ...uploadedFileName,
                                salesFileName: inputFile?.name ?? "",
                                salesFile: inputFile,
                                isSalesFileValidated: true,
                              });
                            }
                          });
                          event.target.value = "";
                        }
                      }}
                      multiple={false}
                      ref={salesFileInputRef}
                    />
                    <div className="download-btn-ss">
                      <button
                        className="template-ss-btn"
                        onClick={() => {
                          DownloadExcelTemplateFile(
                            uploadedFileName.salesFileId,
                            salesFileName
                          );
                        }}
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  <div className="attachment-row">
                    <div className="attachment-title">Waste: </div>
                    <input
                      type="radio"
                      value="existingData"
                      name="waste-file"
                      checked={uploadedFileName.isWasteExistingData}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isWasteExistingData: true,
                          wasteFile: null,
                          wasteFileName: "No file chosen",
                        });
                      }}
                      disabled={!existingDataFlag.waste}
                    />
                    <label className="attachment-value">
                      Reuse Existing Data
                    </label>
                    <input
                      type="radio"
                      className="attachment-value"
                      value="existingData"
                      name="waste-file"
                      checked={isFileUploadSelectedFlag(
                        uploadedFileName.isWasteExistingData
                      )}
                      onClick={() => {
                        setUploadedFileName({
                          ...uploadedFileName,
                          isWasteExistingData: false,
                        });
                      }}
                    />
                    <label className="attachment-value">Upload New</label>
                    <div className="custom-upload">
                      <div className="selected-file-label">
                        {uploadedFileName.wasteFileName}
                      </div>
                      <label
                        className={
                          "upload-btn " +
                          fileEnabledClassName(
                            uploadedFileName.isWasteExistingData
                          )
                        }
                        htmlFor="uploadWaste"
                      >
                        <label className="upload-text" htmlFor="uploadWaste">
                          Choose File
                        </label>
                      </label>
                    </div>
                    <input
                      id="uploadWaste"
                      type="file"
                      disabled={isFileEnableFlag(
                        uploadedFileName.isWasteExistingData
                      )}
                      onChange={(event) => {
                        let inputFile = event.target.files
                          ? event.target.files[0]
                          : null;
                        if (inputFile) {
                          setRecentFileName(wasteFileName);
                          setLoaderFlag(true);
                          SelfServiceExcelValidation(
                            inputFile,
                            uploadedFileName.wasteFileId,
                            dropdownValues
                          ).then((response) => {
                            setLoaderFlag(false);
                            let status: SelfServiceUploadStatus[] = [];
                            status.push(response);
                            setIsValidationMessage(true);
                            setMessages(status);
                            if (
                              response.message[0].indexOf(
                                "File validated successfully"
                              ) === -1
                            ) {
                              setUploadedFileName({
                                ...uploadedFileName,
                                wasteFileName: "No file chosen",
                                wasteFile: null,
                              });
                            } else {
                              setUploadedFileName({
                                ...uploadedFileName,
                                wasteFileName: inputFile?.name ?? "",
                                wasteFile: inputFile,
                                isWasteFileValidated: true,
                              });
                            }
                          });
                          event.target.value = "";
                        }
                      }}
                      multiple={false}
                      ref={wasteFileInputRef}
                    />
                    <div className="download-btn-ss">
                      <button
                        className="template-ss-btn"
                        onClick={() => {
                          DownloadExcelTemplateFile(
                            uploadedFileName.wasteFileId,
                            wasteFileName
                          );
                        }}
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  <div className="selfservice-button">
                    <div className="selfservice-upload-btn">
                      <button
                        className="selfservice-btn upload-btn-ss"
                        disabled={isEnableFileUpload(uploadedFileName, true)}
                        onClick={() => {
                          uploadFileAttachemnts();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                    <div>
                      <button
                        className="selfservice-btn cancel-btn"
                        disabled={isEnableFileUpload(uploadedFileName, false)}
                        onClick={() => {
                          setUploadedFileName({
                            ...uploadedFileName,
                            isProfitExistingData: false,
                            profitFileName: "No file chosen",
                            profitFile: null,
                            isProfitFileValidated: false,
                            isSalesExistingData: false,
                            salesFileName: "No file chosen",
                            salesFile: null,
                            isSalesFileValidated: false,
                            isWasteExistingData: false,
                            wasteFileName: "No file chosen",
                            wasteFile: null,
                            isWasteFileValidated: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row-btn">
          <div className="logs-template-btn">
            <button
              onClick={() => {
                if (!commonFlags.flags.isShowUploadHistory) {
                  refreshFileProcessLog();
                } else {
                  commonFlags.setFlags({
                    ...commonFlags.flags,
                    isShowUploadHistory: false,
                  });
                }
              }}
            >
              {commonFlags.flags.isShowUploadHistory ? "Hide" : "Show"} Upload
              History
            </button>
          </div>

          {commonFlags.flags.isShowUploadHistory && (
            <div
              className="refresh-btn"
              onClick={() => {
                commonFlags.setFlags({
                  ...commonFlags.flags,
                  isRefreshTableIcon: true,
                });
                refreshFileProcessLog();
              }}
            >
              <img
                className={
                  "refresh-icon " +
                  (commonFlags.flags.isRefreshTableIcon ? "rotate-icon" : "")
                }
                src={icon_refresh}
                alt="refresh"
              />
              <label
                className="refresh-label"
                onClick={() => {
                  commonFlags.setFlags({
                    ...commonFlags.flags,
                    isRefreshTableIcon: true,
                  });
                  refreshFileProcessLog();
                }}
              >
                Refresh Data
              </label>
            </div>
          )}
        </div>
      </div>
      {messages.length > 0 && (
        <SelfServicePopup
          messages={messages}
          setMessages={setMessages}
          isValidationMessage={isValidationMessage}
        />
      )}
      {showLoaderFlag && (
        <div className="loader">
          <div className="loader-icon outer-loader"></div>
          <div className="loader-icon inner-loader"></div>
        </div>
      )}
    </>
  );
}
export default SelfService;
