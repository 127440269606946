import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import AdminRequestTable from "../AdminRequestTable/AdminRequestTable";
import {
  checkFileEnableFlag,
  filterValueClassName,
  generateMarketValues,
  generateSelectDataset,
  generateUniqueMarketList,
  slicerClassName,
  updateSlicerList,
  uploadExcelFiletemplate,
} from "../CommonFunctions/CommonFunctions";
import { getUserRoleRequestDetails } from "../CommonFunctions/CommonFunctions";
import {
  AdminTableSlicerConfigurations,
  DropdownConfigurations,
  FileMetaDataConfiguration,
  InputFileMetaData,
  PopupParameter,
  RoleRequestConfigurations,
} from "../Interface/Interface";
import {
  filterstyle,
  requestPortalTabName,
  selectAllText,
  uploadTempplateTabName,
} from "../Constants/Constants";
import UserDetails from "../UserDetails/UserDetails";
import AdminPopup from "../Popup/AdminPopup";
import FileNamePopup from "../Popup/FileNamePopup";
import {
  CommonFlagConfigContext,
  CoreUserConfigContext,
  FileMetadataContext,
} from "../ContextAPI/ContextAPI";
import icon_down_arrow from "../Images/icon-down-arrow.svg";
import icon_refresh from "../Images/icon-refresh.svg";
import "./Admin.css";

function generateSlicerTitle(values: string[], type: string) {
  let selectAllIndex: number = values.indexOf(selectAllText);

  if (values.length === 0) {
    return type;
  } else if (values.length === 1) {
    return values[0].charAt(0).toUpperCase() + values[0].slice(1);
  } else if (values.length > 1 && selectAllIndex !== -1) {
    return selectAllText;
  } else {
    return (
      values[0].charAt(0).toUpperCase() +
      values[0].slice(1) +
      " + " +
      (values.length - 1)
    );
  }
}

function generateSlicerTitleClass(values: string[]) {
  if (values.length === 0) {
    return "";
  } else {
    return "active-slicer";
  }
}

function filterRequestData(
  data: RoleRequestConfigurations[],
  slicerValues: AdminTableSlicerConfigurations
) {
  let slicedData: RoleRequestConfigurations[] = data.sort(
    (firstItem, secondItem) => {
      if (firstItem.createdDate > secondItem.createdDate) return -1;
      return 1;
    }
  );
  if (slicerValues.selectedMarketValues.length > 0) {
    slicedData = slicedData.filter(
      (item) => slicerValues.selectedMarketValues.indexOf(item.buName) !== -1
    );
  }
  if (slicerValues.selectedGPIDValues.length > 0) {
    slicedData = slicedData.filter(
      (item) => slicerValues.selectedGPIDValues.indexOf(item.gpid) !== -1
    );
  }
  if (slicerValues.selectedStatus.length > 0) {
    slicedData = slicedData.filter(
      (item) => slicerValues.selectedStatus.indexOf(item.status) !== -1
    );
  }
  return slicedData;
}

function uploadTemplateClassName(filename: string): string {
  let className = "disable-input";
  if (filename !== "") {
    className = "pointer";
  }
  return className;
}

function updateDisableFlag(
  dropdownValues: DropdownConfigurations,
  uploadedFile: any
) {
  return (
    dropdownValues.generation === "" ||
    dropdownValues.market === "" ||
    dropdownValues.metric === "" ||
    dropdownValues.fileName === "" ||
    uploadedFile === null
  );
}

function Admin(props: {
  popupParameters: PopupParameter;
  setPopupParameters: Dispatch<SetStateAction<PopupParameter>>;
}) {
  const coreUserConfig = useContext(CoreUserConfigContext);
  const fileMetaDataContext = useContext(FileMetadataContext);
  const commonFlags = useContext(CommonFlagConfigContext);
  const [openSearchPopupFlag, setOpenSearchPopupFlag] = useState(false);
  const [requestData, setRequestData] = useState<RoleRequestConfigurations[]>(
    []
  );
  const [adminTableSlicer, setAdminTableSlicer] =
    useState<AdminTableSlicerConfigurations>({
      marketList: [],
      gpIdList: [],
      status: [],
      selectedMarketValues: [],
      selectedGPIDValues: [],
      selectedStatus: ["pending"],
    });
  const [selectedSubTab, setSelectedSubTab] =
    useState<string>(requestPortalTabName);
  const [showLoaderFlag, setLoaderFlag] = useState<boolean>(false);
  const [requestCount, setRequestCount] = useState({ completed: 0, total: 0 });
  const [approvalMessage, setApprovalMessage] = useState("");
  const [approvalMessagePopup, setApprovalMessagePopup] = useState(false);
  const [isDisplayError, setIsDisplayError] = useState(false);
  const [activeSlicer, setActiveSlicer] = useState("");

  const [refreshFlag, setRefreshFlag] = useState(false);
  const [dropdownValues, setDropDownValues] = useState<DropdownConfigurations>({
    openedDropDown: "",
    generation: "",
    market: "",
    metric: "",
    fileName: "",
    fullOverrideFile: "",
    startYear: "",
    startPeriod: "",
    endYear: "",
    endPeriod: "",
    isRequired: -1,
  });

  const blankArray: string[] = [];
  const [dropdownConfigurations, setDropdownConfigurations] = useState({
    generation: blankArray,
    market: blankArray,
    metric: blankArray,
    FIleName: blankArray,
    fullOverrideFile: [],
  });
  const [uploadedFileName, setUploadedFileName] =
    useState<FileMetaDataConfiguration>({
      fileName: "No file chosen",
      file: null,
    });
  const manualUploadMetaData = fileMetaDataContext.data;

  const getRequestDetails = () => {
    getUserRoleRequestDetails().then((responseData) => {
      setRefreshFlag(false);
      if (responseData.length > 0) {
        setRequestData(responseData);
        const allOption: string[] = [selectAllText];
        const marketValues = responseData
          .map((item: RoleRequestConfigurations) => item.buName)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((firstValue, secondValue) => {
            if (firstValue > secondValue) {
              return 1;
            } else {
              return -1;
            }
          });
        const gpidValues = responseData
          .map((item: RoleRequestConfigurations) => item.gpid)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((firstValue, secondValue) => {
            if (firstValue > secondValue) {
              return 1;
            } else {
              return -1;
            }
          });
        const statusValues = responseData
          .map((item: RoleRequestConfigurations) => item.status)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((firstValue, secondValue) => {
            if (firstValue > secondValue) {
              return 1;
            } else {
              return -1;
            }
          });
        setAdminTableSlicer({
          ...adminTableSlicer,
          marketList: allOption.concat(marketValues),
          gpIdList: allOption.concat(gpidValues),
          status: allOption.concat(statusValues),
        });
      }
    });
  };

  useEffect(() => {
    getRequestDetails();
  }, []);

  useEffect(() => {
    if (!approvalMessagePopup) {
      getRequestDetails();
    }
  }, [approvalMessagePopup]);

  useEffect(() => {
    if (dropdownConfigurations.market.length === 0) {
      const marketValues: string[] =
        generateUniqueMarketList(manualUploadMetaData);
      setDropdownConfigurations({
        ...dropdownConfigurations,
        market: marketValues,
      });
    }
  }, [fileMetaDataContext.data, dropdownConfigurations.market.length]);

  useEffect(() => {
    setUploadedFileName({ fileName: "No file chosen", file: null });
    setDropDownValues({
      ...dropdownValues,
      generation: "",
      market: "",
      metric: "",
      fileName: "",
    });
  }, [selectedSubTab]);
  function useRequestFormOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActiveSlicer("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const marketDropdownValues: string[] = generateMarketValues(
    coreUserConfig.config.BU,
    dropdownConfigurations.market
  );
  const filteredRequestData = filterRequestData(requestData, adminTableSlicer);
  const requestDropDownRef = useRef(null);
  useRequestFormOutsideAlerter(requestDropDownRef);

  const fileTemplateRef: any = useRef(null);

  return (
    <>
      <div className="admin-section">
        <div className="admin-subtab">
          <div
            className={`subtab-title request-tab ${
              selectedSubTab === requestPortalTabName ? "active-subtab" : ""
            }`}
            onClick={() => {
              setSelectedSubTab(requestPortalTabName);
            }}
          >
            Request Portal
          </div>
          <div
            className={`subtab-title upload-tab ${
              selectedSubTab === uploadTempplateTabName ? "active-subtab" : ""
            }`}
            onClick={() => {
              setSelectedSubTab(uploadTempplateTabName);
            }}
          >
            Upload Template
          </div>
        </div>
        {selectedSubTab === requestPortalTabName ? (
          <>
            <div className="slicer-section">
              <div className="market-slicer">
                <div className="slicer-title">Market</div>
                <div
                  className="market-dropdown"
                  onClick={() =>
                    setActiveSlicer(activeSlicer === "market" ? "" : "market")
                  }
                >
                  <div
                    className={
                      "market-dropdown-title " +
                      generateSlicerTitleClass(
                        adminTableSlicer.selectedMarketValues
                      )
                    }
                  >
                    {generateSlicerTitle(
                      adminTableSlicer.selectedMarketValues,
                      "Select Market"
                    )}
                  </div>
                  <img
                    className="icon-down-arrow"
                    src={icon_down_arrow}
                    alt=""
                  />
                </div>
                {activeSlicer === "market" && (
                  <div className="market-slicer-list" ref={requestDropDownRef}>
                    {adminTableSlicer.marketList.map((marketValue: string) => {
                      return (
                        <div
                          className="dropdown-list-item"
                          onClick={() => {
                            const selectedMarketValues = updateSlicerList(
                              adminTableSlicer.marketList,
                              adminTableSlicer.selectedMarketValues,
                              marketValue
                            );
                            setAdminTableSlicer({
                              ...adminTableSlicer,
                              selectedMarketValues: selectedMarketValues,
                            });
                          }}
                        >
                          <input
                            className="value-check"
                            type="checkbox"
                            checked={
                              filterValueClassName(
                                adminTableSlicer.selectedMarketValues,
                                marketValue
                              ) === "selected-value"
                            }
                          />
                          <label
                            className={filterValueClassName(
                              adminTableSlicer.selectedMarketValues,
                              marketValue
                            )}
                          >
                            {marketValue}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="market-slicer">
                <div className="slicer-title">GPID</div>
                <div
                  className="market-dropdown"
                  onClick={() =>
                    setActiveSlicer(activeSlicer === "gpid" ? "" : "gpid")
                  }
                >
                  <div
                    className={
                      "market-dropdown-title " +
                      generateSlicerTitleClass(
                        adminTableSlicer.selectedGPIDValues
                      )
                    }
                  >
                    {generateSlicerTitle(
                      adminTableSlicer.selectedGPIDValues,
                      "Select GPID"
                    )}
                  </div>
                  <img
                    className="icon-down-arrow"
                    src={icon_down_arrow}
                    alt=""
                  />
                </div>
                {activeSlicer === "gpid" && (
                  <div className="market-slicer-list" ref={requestDropDownRef}>
                    {adminTableSlicer.gpIdList.map((gpidValue: string) => {
                      return (
                        <div
                          className="dropdown-list-item"
                          onClick={() => {
                            const selectedGPIDValues = updateSlicerList(
                              adminTableSlicer.gpIdList,
                              adminTableSlicer.selectedGPIDValues,
                              gpidValue
                            );
                            setAdminTableSlicer({
                              ...adminTableSlicer,
                              selectedGPIDValues: selectedGPIDValues,
                            });
                          }}
                        >
                          <input
                            className="value-check"
                            type="checkbox"
                            checked={
                              filterValueClassName(
                                adminTableSlicer.selectedGPIDValues,
                                gpidValue
                              ) === "selected-value"
                            }
                          />
                          <label
                            className={filterValueClassName(
                              adminTableSlicer.selectedGPIDValues,
                              gpidValue
                            )}
                          >
                            {gpidValue}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="market-slicer">
                <div className="slicer-title">Status</div>
                <div
                  className="market-dropdown"
                  onClick={() =>
                    setActiveSlicer(activeSlicer === "status" ? "" : "status")
                  }
                >
                  <div
                    className={
                      "market-dropdown-title " +
                      generateSlicerTitleClass(adminTableSlicer.selectedStatus)
                    }
                  >
                    {generateSlicerTitle(
                      adminTableSlicer.selectedStatus,
                      "Select Status"
                    )}
                  </div>
                  <img
                    className="icon-down-arrow"
                    src={icon_down_arrow}
                    alt=""
                  />
                </div>
                {activeSlicer === "status" && (
                  <div className="market-slicer-list" ref={requestDropDownRef}>
                    {adminTableSlicer.status.map((status: string) => {
                      return (
                        <div
                          className="dropdown-list-item"
                          onClick={() => {
                            const selectedStatus = updateSlicerList(
                              adminTableSlicer.status,
                              adminTableSlicer.selectedStatus,
                              status
                            );
                            setAdminTableSlicer({
                              ...adminTableSlicer,
                              selectedStatus: selectedStatus,
                            });
                          }}
                        >
                          <input
                            className="value-check"
                            type="checkbox"
                            checked={
                              filterValueClassName(
                                adminTableSlicer.selectedStatus,
                                status
                              ) === "selected-value"
                            }
                          />
                          <label
                            className={filterValueClassName(
                              adminTableSlicer.selectedGPIDValues,
                              status
                            )}
                          >
                            {status.charAt(0).toUpperCase()}
                            {status.slice(1)}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="refresh-table-btn">
                <div className="refresh-table-icon">
                  <img
                    className={
                      "refresh-icon " + (refreshFlag ? "rotate-icon" : "")
                    }
                    src={icon_refresh}
                    alt="refresh"
                    title="Refresh Table"
                    onClick={() => {
                      setRefreshFlag(true);
                      getRequestDetails();
                    }}
                  />
                </div>
              </div>
              <div className="search-user-btn">
                <div
                  className="search-btn"
                  title="Check user access by GPID or Email ID"
                  onClick={() => setOpenSearchPopupFlag(true)}
                >
                  Search User
                </div>
              </div>
            </div>
            {filteredRequestData.length > 0 && (
              <AdminRequestTable
                requestData={filteredRequestData}
                setApprovalMessage={setApprovalMessage}
                setApprovalMessagePopup={setApprovalMessagePopup}
                setLoaderFlag={setLoaderFlag}
                setRequestCount={setRequestCount}
              />
            )}
            {openSearchPopupFlag && (
              <UserDetails setOpenSearchPopupFlag={setOpenSearchPopupFlag} />
            )}
          </>
        ) : (
          <>
            <div className="selection-dropdown admin-upload-section">
              <div className="dropdown-row">
                <div className="market-section dropdown-list dropdown-col1">
                  <label className="dropdown-title">Market</label>
                  <Select
                    classNamePrefix="react-select"
                    options={generateSelectDataset(marketDropdownValues)}
                    isSearchable={false}
                    placeholder="Select Market"
                    isClearable={false}
                    hideSelectedOptions={false}
                    styles={filterstyle}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        dropdownValues.market = selectedOption.value;
                        dropdownValues.generation = "";
                        dropdownValues.metric = "";
                        dropdownValues.fileName = "";
                        dropdownValues.fullOverrideFile = "";
                        dropdownValues.isRequired = -1;
                        dropdownValues.startYear = "";
                        dropdownValues.startPeriod = "";
                        dropdownValues.endYear = "";
                        dropdownValues.endPeriod = "";
                        let tempFileData: InputFileMetaData[] =
                          manualUploadMetaData;
                        tempFileData = tempFileData.filter(
                          (fileData: InputFileMetaData) =>
                            fileData.Market === selectedOption.value
                        );
                        let generationValues: string[] = tempFileData
                          .map((item: InputFileMetaData) => item.Generation)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          );
                        generationValues.sort();
                        setDropdownConfigurations({
                          ...dropdownConfigurations,
                          generation: generationValues,
                          metric: blankArray,
                          FIleName: blankArray,
                        });
                        setUploadedFileName({
                          fileName: "No file chosen",
                          file: null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="generation-section dropdown-list dropdown-col2">
                  <label className="dropdown-title">Generation</label>
                  <Select
                    classNamePrefix="react-select"
                    options={generateSelectDataset(
                      dropdownConfigurations.generation
                    )}
                    isSearchable={false}
                    placeholder="Select Generation"
                    isClearable={false}
                    hideSelectedOptions={false}
                    styles={filterstyle}
                    key={"react-select-" + dropdownValues.market}
                    isDisabled={slicerClassName(dropdownValues.market)}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        dropdownValues.generation = selectedOption.value;
                        dropdownValues.metric = "";
                        dropdownValues.fileName = "";
                        let tempFileData: InputFileMetaData[] =
                          manualUploadMetaData;
                        tempFileData = tempFileData.filter(
                          (fileData: InputFileMetaData) =>
                            fileData.Market === dropdownValues.market &&
                            fileData.Generation === selectedOption.value
                        );
                        let metricValues: string[] = tempFileData
                          .map((item: InputFileMetaData) => item.Metric)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          );
                        metricValues.sort();
                        setDropdownConfigurations({
                          ...dropdownConfigurations,
                          metric: metricValues,
                          FIleName: blankArray,
                        });

                        setUploadedFileName({
                          fileName: "No file chosen",
                          file: null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="metric-section dropdown-list dropdown-col3">
                  <label className="dropdown-title">Metric Value</label>
                  <Select
                    classNamePrefix="react-select"
                    options={generateSelectDataset(
                      dropdownConfigurations.metric
                    )}
                    isSearchable={false}
                    placeholder="Select Metric Value"
                    isClearable={false}
                    hideSelectedOptions={false}
                    styles={filterstyle}
                    key={"react-select-metric" + dropdownValues.generation}
                    isDisabled={slicerClassName(dropdownValues.generation)}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        dropdownValues.metric = selectedOption.value;
                        dropdownValues.fileName = "";
                        let tempFileData: InputFileMetaData[] =
                          manualUploadMetaData;
                        tempFileData = tempFileData.filter(
                          (fileData: InputFileMetaData) =>
                            fileData.Generation === dropdownValues.generation &&
                            fileData.Market === dropdownValues.market &&
                            fileData.Metric === selectedOption.value
                        );
                        let fileNames: string[] = tempFileData
                          .map((item: InputFileMetaData) => item.FileName)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          );
                        fileNames.sort();
                        setDropdownConfigurations({
                          ...dropdownConfigurations,
                          FIleName: fileNames,
                        });

                        setUploadedFileName({
                          fileName: "No file chosen",
                          file: null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="file-section dropdown-list dropdown-col4">
                  <label className="dropdown-title">File Name</label>
                  <Select
                    classNamePrefix="react-select"
                    options={generateSelectDataset(
                      dropdownConfigurations.FIleName
                    )}
                    isSearchable={false}
                    placeholder="Select File"
                    isClearable={false}
                    hideSelectedOptions={false}
                    styles={filterstyle}
                    key={"react-select-" + dropdownValues.metric}
                    isDisabled={slicerClassName(dropdownValues.metric)}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        dropdownValues.fileName = selectedOption.value;
                        setDropdownConfigurations({
                          ...dropdownConfigurations,
                          FIleName: dropdownConfigurations.FIleName,
                        });

                        setUploadedFileName({
                          fileName: "No file chosen",
                          file: null,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="upload-parent-section">
                <div className="upload-section">
                  <div className="upload-btn-wrap">
                    <label className="dropdown-title">File Attachment</label>
                    <div className={"custom-upload"}>
                      <div className="selected-file-label">
                        {uploadedFileName.fileName}
                      </div>
                      <label
                        className={
                          "upload-btn " +
                          uploadTemplateClassName(dropdownValues.fileName)
                        }
                        htmlFor="upload"
                      >
                        <label className="upload-text" htmlFor="upload">
                          Choose File
                        </label>
                      </label>
                    </div>
                    <input
                      id="upload"
                      type="file"
                      disabled={checkFileEnableFlag(dropdownValues.fileName)}
                      onChange={(event) => {
                        let inputFile = event.target.files
                          ? event.target.files[0]
                          : null;
                        if (
                          inputFile &&
                          inputFile.name === dropdownValues.fileName + ".xlsx"
                        ) {
                          setUploadedFileName({
                            fileName: inputFile.name,
                            file: inputFile,
                          });
                          event.target.value = "";
                        } else {
                          setIsDisplayError(true);
                        }
                      }}
                      multiple={false}
                      ref={fileTemplateRef}
                    />
                  </div>
                </div>
              </div>
              <div className="template-btn">
                <div className="download-btn">
                  <button
                    onClick={async () => {
                      commonFlags.setFlags({
                        ...commonFlags.flags,
                        isRefreshPageIcon: true,
                      });
                      if (uploadedFileName.file) {
                        const fileId = fileMetaDataContext.data.filter(
                          (metadata: InputFileMetaData) =>
                            metadata.Market === dropdownValues.market &&
                            metadata.Generation === dropdownValues.generation &&
                            metadata.Metric === dropdownValues.metric &&
                            metadata.FileName === dropdownValues.fileName
                        )[0].FileId;
                        await uploadExcelFiletemplate(
                          uploadedFileName.file,
                          fileId,
                          props.popupParameters,
                          props.setPopupParameters
                        );
                        commonFlags.setFlags({
                          ...commonFlags.flags,
                          isRefreshPageIcon: false,
                        });
                      }
                    }}
                    disabled={updateDisableFlag(
                      dropdownValues,
                      uploadedFileName.file
                    )}
                  >
                    Upload Template
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {approvalMessagePopup && (
        <AdminPopup
          message={approvalMessage}
          setApprovalMessagePopup={setApprovalMessagePopup}
          setApprovalMessage={setApprovalMessage}
        />
      )}
      {showLoaderFlag && (
        <div className="loader">
          <div className="loader-icon outer-loader"></div>
          <div className="loader-icon inner-loader"></div>
          <div className="loader-text">{`Progress: ${requestCount.completed}/${requestCount.total}`}</div>
        </div>
      )}
      {isDisplayError && (
        <FileNamePopup
          fileName={dropdownValues.fileName}
          setIsDisplayError={setIsDisplayError}
        />
      )}
    </>
  );
}
export default Admin;
