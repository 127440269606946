import React, { Dispatch, SetStateAction } from "react";
import { SelfServiceUploadStatus } from "../Interface/Interface";
import Icon_close_circle from "./../Images/Icon-close-circle.svg";
import icon_connection_failed from "../Images/icon-connection-failed.svg";
import icon_connection_success from "../Images/icon-connection-success.svg";
import "./SelfServicePopup.css";

function checkIsFailure(messages: SelfServiceUploadStatus[]): boolean {
  const indexFilure: number = messages.findIndex(
    (value: SelfServiceUploadStatus) => {
      return !value.isSuccessFlag;
    }
  );
  return indexFilure === -1 ? true : false;
}

function SelfServicePopup(props: {
  messages: SelfServiceUploadStatus[];
  setMessages: Dispatch<SetStateAction<SelfServiceUploadStatus[]>>;
  isValidationMessage: boolean;
}) {
  return (
    <div className="popup-bgblur-ss">
      <div className="popup-layer-ss">
        <div className="popup-header">
          <div className="status-title">
            <div>
              <img
                className="status-icon"
                src={
                  checkIsFailure(props.messages)
                    ? icon_connection_success
                    : icon_connection_failed
                }
                alt=""
              />
            </div>
            <label className="status-text-ss">
              {`File ${
                props.isValidationMessage ? "Validation" : "Upload"
              } Status!!!`}
            </label>
          </div>
          <div
            className="close-icon-ss"
            onClick={() => {
              props.setMessages([]);
            }}
          >
            <img src={Icon_close_circle} alt="" />
          </div>
        </div>
        <div className="popup-title-ss popup-status-message-ss">
          <div className="request-status-list-ss">
            {props.messages.length === 1 ? (
              <div className="popup-status-single-message">
                {props.messages[0].message}
              </div>
            ) : (
              <ul className="file-list-ss">
                {props.messages.map((list) =>
                  props.isValidationMessage ? (
                    <ol>
                      {list.message.map((value) => (
                        <li>{value}</li>
                      ))}
                    </ol>
                  ) : (
                    <li>
                      <p>
                        <b>{list.fileName + ": "}</b>
                        {list.message}
                      </p>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelfServicePopup;
