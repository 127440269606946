import React, { useContext, useEffect, useRef, useState } from "react";
import {
  checkGPIDField,
  checkRequestButtonState,
  filterValueClassName,
  generateDropdownTitle,
  updateSlicerList,
  submitUserRoleRequest,
} from "../CommonFunctions/CommonFunctions";
import { selectAllText } from "../Constants/Constants";
import {
  CommonFlagConfigContext,
  CoreUserConfigContext,
} from "../ContextAPI/ContextAPI";
import {
  RequestStatusConfigurations,
  SearchRoleConfigurations,
} from "../Interface/Interface";
import RequestFormPopup from "../Popup/RequestFormPopup";
import icon_down_arrow from "../Images/icon-down-arrow.svg";
import "./ModifyRequestForm.css";

function ModifyRequestForm(props: {
  allMarketList: string[];
  approvedMarketList?: string[];
}) {
  const coreUserConfig = useContext(CoreUserConfigContext);
  const commonFlags = useContext(CommonFlagConfigContext);
  const [market, setMarketValues] = useState<string[]>([]);
  const [marketDropDown, setMarketDropDown] = useState<string[]>([]);
  const [popupOpenFlag, setPopupOpenFlag] = useState<boolean>(false);
  const [requestObject, setRequestObject] = useState<SearchRoleConfigurations>({
    emailID: "",
    gpid: "",
    buNames: [],
  });
  const [requestPopupFlag, setRequestPopupFlag] = useState<boolean>(false);
  const [requestStatusDetails, setRequestStatusDetails] = useState<
    RequestStatusConfigurations[]
  >([]);
  useEffect(() => {
    const allOption: string[] = [selectAllText];
    let remainingMarketList = props.allMarketList;
    if (props.approvedMarketList) {
      remainingMarketList = remainingMarketList.filter(
        (market: string) => props.approvedMarketList?.indexOf(market) === -1
      );
    }
    setMarketValues(
      remainingMarketList.length > 1
        ? allOption.concat(remainingMarketList)
        : remainingMarketList
    );
  }, [props.allMarketList]);
  useEffect(() => {
    setRequestObject({
      ...requestObject,
      emailID: coreUserConfig.config.userEmail,
      gpid: coreUserConfig.config.gpid,
    });
  }, [coreUserConfig.config]);
  function useRequestFormOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPopupOpenFlag(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const requestDropDownRef = useRef(null);
  useRequestFormOutsideAlerter(requestDropDownRef);
  return (
    <>
      <div className="request-form">
        <div className="gpid-details">
          <label className="field-title">GPID</label>
          <input
            className="input-field"
            type="text"
            placeholder="Enter GPID"
            value={requestObject.gpid}
            readOnly={checkGPIDField(requestObject.gpid)}
            onChange={(event) => {
              setRequestObject({ ...requestObject, gpid: event.target.value });
            }}
          />
        </div>
        <div className="market-details">
          <label className="field-title">Markets</label>
          <div
            className="market-dropdown"
            onClick={() => {
              setPopupOpenFlag(!popupOpenFlag);
            }}
          >
            <div className="market-dropdown-title">
              {generateDropdownTitle(marketDropDown)}
            </div>
            <img className="icon-down-arrow" src={icon_down_arrow} alt="" />
          </div>
          {popupOpenFlag && (
            <div className="market-dropdown-list" ref={requestDropDownRef}>
              {market.map((marketValue: string) => {
                return (
                  <div
                    className="dropdown-list-item"
                    onClick={() => {
                      const selectedMarketValues = updateSlicerList(
                        market,
                        marketDropDown,
                        marketValue
                      );
                      setMarketDropDown(selectedMarketValues);
                      let selectAllIndex: number =
                        selectedMarketValues.indexOf(selectAllText);
                      let requestedMarkets = selectedMarketValues.map(
                        (value: string) => value
                      );
                      if (selectAllIndex !== -1) {
                        requestedMarkets.splice(selectAllIndex, 1);
                      }
                      setRequestObject({
                        ...requestObject,
                        buNames: requestedMarkets,
                      });
                    }}
                  >
                    <input
                      className="value-check"
                      type="checkbox"
                      checked={
                        filterValueClassName(marketDropDown, marketValue) ===
                        "selected-value"
                      }
                    />
                    <label
                      className={filterValueClassName(
                        marketDropDown,
                        marketValue
                      )}
                    >
                      {marketValue}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="btn-col">
          <button
            className="submit-btn"
            disabled={checkRequestButtonState(requestObject)}
            onClick={() => {
              commonFlags.setFlags({
                ...commonFlags.flags,
                isRefreshPageIcon: true,
              });
              if (requestObject.buNames.length > 0) {
                submitUserRoleRequest(requestObject).then((reqStatus) => {
                  if (reqStatus.length > 0) {
                    setRequestPopupFlag(true);
                    setRequestStatusDetails(reqStatus);
                  }
                  commonFlags.setFlags({
                    ...commonFlags.flags,
                    isRefreshPageIcon: false,
                  });
                  setMarketDropDown([]);
                  setRequestObject({ ...requestObject, buNames: [] });
                });
              }
            }}
          >
            Request Access
          </button>
        </div>
      </div>
      {requestPopupFlag && (
        <RequestFormPopup
          requestStatusDetails={requestStatusDetails}
          setRequestStatusDetails={setRequestStatusDetails}
          setRequestPopupFlag={setRequestPopupFlag}
        />
      )}
    </>
  );
}
export default ModifyRequestForm;
