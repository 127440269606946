import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  RoleRequestConfigurations,
  SortObjectDetails,
} from "../Interface/Interface";
import { updateRequestStatus } from "../CommonFunctions/CommonFunctions";
import icon_ascending_sort from "./../Images/icon-ascending-sort.svg";
import icon_descending_sort from "./../Images/icon-descending-sort.svg";
import icon_down_arrow from "../Images/icon-down-arrow.svg";
import icon_down_arrow_grey from "../Images/icon-down-arrow-grey.svg";
import "./AdminRequestTable.css";

function generateSortIcon(sortOrder: number) {
  if (sortOrder === 1) {
    return icon_ascending_sort;
  } else {
    return icon_descending_sort;
  }
}

function generateSortOrderDetails(sortObject: SortObjectDetails, key: string) {
  let sortDetails: SortObjectDetails = {
    key: key,
    order: 1,
  };
  if (key === sortObject.key) {
    sortDetails.order = -sortObject.order;
  } else {
    sortDetails.order = 1;
  }
  return sortDetails;
}

function sortRequestData(
  requestData: RoleRequestConfigurations[],
  sortObject: SortObjectDetails
) {
  let sortedData: RoleRequestConfigurations[] = requestData;
  if (sortObject.key !== "") {
    sortedData = sortedData.sort((firstValue: any, secondValue: any) => {
      if (
        firstValue[sortObject.key].toLowerCase() >
        secondValue[sortObject.key].toLowerCase()
      ) {
        return sortObject.order;
      } else {
        return -sortObject.order;
      }
    });
  }
  return sortedData;
}

function updateRequestApprovalStatus(
  requestForApproval: RoleRequestConfigurations[],
  requestForRejection: RoleRequestConfigurations[],
  item: RoleRequestConfigurations,
  isApproved: boolean
) {
  const approvalRequestList = requestForApproval;
  const requestApprovalIndex = approvalRequestList.findIndex(
    (request: RoleRequestConfigurations) => request.id === item.id
  );
  const rejectionRequestList = requestForRejection;
  const requestRejectionIndex = rejectionRequestList.findIndex(
    (request: RoleRequestConfigurations) => request.id === item.id
  );
  if (requestApprovalIndex === -1 && isApproved) {
    approvalRequestList.push(item);
  } else if (isApproved) {
    approvalRequestList.splice(requestApprovalIndex, 1);
  } else if (requestRejectionIndex === -1 && !isApproved) {
    rejectionRequestList.push(item);
  } else {
    rejectionRequestList.splice(requestRejectionIndex, 1);
  }
  if (requestRejectionIndex !== -1 && isApproved) {
    rejectionRequestList.splice(requestRejectionIndex, 1);
  } else if (requestApprovalIndex !== -1 && !isApproved) {
    approvalRequestList.splice(requestApprovalIndex, 1);
  }
  return {
    rejectionRequestList: rejectionRequestList,
    approvalRequestList: approvalRequestList,
  };
}

function AdminRequestTable(props: {
  requestData: RoleRequestConfigurations[];
  setApprovalMessage: Dispatch<SetStateAction<string>>;
  setApprovalMessagePopup: Dispatch<SetStateAction<boolean>>;
  setLoaderFlag: Dispatch<SetStateAction<boolean>>;
  setRequestCount: Dispatch<
    SetStateAction<{ completed: number; total: number }>
  >;
}) {
  const [sortObject, setSortObject] = useState<SortObjectDetails>({
    key: "createdDate",
    order: -1,
  });
  const [buttonState, setButtonState] = useState({
    approvebtn: false,
    rejectbtn: false,
  });
  const [requestForApproval, setRequestForApproval] = useState<
    RoleRequestConfigurations[]
  >([]);

  const [requestForRejection, setRequestForRejection] = useState<
    RoleRequestConfigurations[]
  >([]);

  const [onClickEventDetection, setOnClickEventDetection] =
    useState<boolean>(false);

  const [requestListObject, setRequestListObject] = useState<
    RoleRequestConfigurations[]
  >(props.requestData);

  const [dataChangeEventDetection, setDataChangeEventDetection] =
    useState<boolean>(false);

  useEffect(() => {
    const data: RoleRequestConfigurations[] = props.requestData;
    for (let iterator = 0; iterator < data.length; iterator++) {
      const approveListIndex: number = requestForApproval.findIndex(
        (request: RoleRequestConfigurations) => request.id === data[iterator].id
      );
      const rejectionListIndex: number = requestForRejection.findIndex(
        (request: RoleRequestConfigurations) => request.id === data[iterator].id
      );
      if (approveListIndex !== -1) {
        data[iterator].isSelectedForApproval = true;
      } else {
        data[iterator].isSelectedForApproval = false;
      }
      if (rejectionListIndex !== -1) {
        data[iterator].isSelectedForRejection = true;
      } else {
        data[iterator].isSelectedForRejection = false;
      }
    }
    setRequestListObject(data);
    setDataChangeEventDetection(!dataChangeEventDetection);
  }, [onClickEventDetection, props.requestData]);

  const sortedRequestData: RoleRequestConfigurations[] = sortRequestData(
    requestListObject,
    sortObject
  );
  function useButtonOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setButtonState({
            approvebtn: false,
            rejectbtn: false,
          });
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const buttonDropDownRef = useRef(null);
  useButtonOutsideAlerter(buttonDropDownRef);
  return (
    <>
      <div className="request-list">
        <table className="request-table">
          <tr className="column-header">
            <th
              className="column-header-cell pointer"
              onClick={() => {
                const sortDetails = generateSortOrderDetails(
                  sortObject,
                  "buName"
                );
                setSortObject({
                  ...sortObject,
                  key: sortDetails.key,
                  order: sortDetails.order,
                });
              }}
            >
              Markets
              {sortObject.key === "buName" && (
                <img
                  className="sorting-icon"
                  src={generateSortIcon(sortObject.order)}
                  alt="Sorting-Icon"
                />
              )}
            </th>
            <th
              className="column-header-cell pointer"
              onClick={() => {
                const sortDetails = generateSortOrderDetails(
                  sortObject,
                  "gpid"
                );
                setSortObject({
                  ...sortObject,
                  key: sortDetails.key,
                  order: sortDetails.order,
                });
              }}
            >
              GPID
              {sortObject.key === "gpid" && (
                <img
                  className="sorting-icon"
                  src={generateSortIcon(sortObject.order)}
                  alt="Sorting-Icon"
                />
              )}
            </th>
            <th
              className="column-header-cell pointer"
              onClick={() => {
                const sortDetails = generateSortOrderDetails(
                  sortObject,
                  "emailID"
                );
                setSortObject({
                  ...sortObject,
                  key: sortDetails.key,
                  order: sortDetails.order,
                });
              }}
            >
              Email ID
              {sortObject.key === "emailID" && (
                <img
                  className="sorting-icon"
                  src={generateSortIcon(sortObject.order)}
                  alt="Sorting-Icon"
                />
              )}
            </th>
            <th
              className="column-header-cell pointer"
              onClick={() => {
                const sortDetails = generateSortOrderDetails(
                  sortObject,
                  "createdDate"
                );
                setSortObject({
                  ...sortObject,
                  key: sortDetails.key,
                  order: sortDetails.order,
                });
              }}
            >
              Requested Date
              {sortObject.key === "createdDate" && (
                <img
                  className="sorting-icon"
                  src={generateSortIcon(sortObject.order)}
                  alt="Sorting-Icon"
                />
              )}
            </th>
            <th className="column-header-cell">Role Type</th>
            <th
              className="column-header-cell pointer"
              onClick={() => {
                const sortDetails = generateSortOrderDetails(
                  sortObject,
                  "status"
                );
                setSortObject({
                  ...sortObject,
                  key: sortDetails.key,
                  order: sortDetails.order,
                });
              }}
            >
              Current Status
              {sortObject.key === "status" && (
                <img
                  className="sorting-icon"
                  src={generateSortIcon(sortObject.order)}
                  alt="Sorting-Icon"
                />
              )}
            </th>
            <th className="column-header-cell">Approve</th>
            <th className="column-header-cell">Reject</th>
          </tr>
          {sortedRequestData.map((item) => {
            return (
              <tr className="column-value">
                <td className="column-value-cell">{item.buName}</td>
                <td className="column-value-cell">{item.gpid}</td>
                <td className="column-value-cell">{item.emailID}</td>
                <td className="column-value-cell">{item.createdDate}</td>
                <td className="column-value-cell">Core User</td>
                <td className="column-value-cell">{`${item.status
                  .charAt(0)
                  .toUpperCase()}${item.status.slice(1)}`}</td>
                <td className="column-value-cell">
                  {item.status === "pending" && (
                    <input
                      type="checkbox"
                      name="request-status"
                      className="approve-check"
                      checked={item.isSelectedForApproval}
                      onChange={() => {
                        let updatedList = updateRequestApprovalStatus(
                          requestForApproval,
                          requestForRejection,
                          item,
                          true
                        );
                        setRequestForRejection(
                          updatedList.rejectionRequestList
                        );
                        setRequestForApproval(updatedList.approvalRequestList);
                        setOnClickEventDetection(!onClickEventDetection);
                      }}
                    />
                  )}
                </td>
                <td className="column-value-cell">
                  {item.status === "pending" && (
                    <input
                      type="checkbox"
                      name="request-status"
                      className="reject-check"
                      checked={item.isSelectedForRejection}
                      onChange={() => {
                        let updatedList = updateRequestApprovalStatus(
                          requestForApproval,
                          requestForRejection,
                          item,
                          false
                        );
                        setRequestForRejection(
                          updatedList.rejectionRequestList
                        );
                        setRequestForApproval(updatedList.approvalRequestList);
                        setOnClickEventDetection(!onClickEventDetection);
                      }}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="request-submit-section">
        <div className="approve-btn-cell">
          <div className="approve-btn">
            <div
              className={
                "approve-req-btn " +
                (requestForApproval.length > 0 ? "active-btn" : "")
              }
              onClick={() => {
                if (requestForApproval.length > 0) {
                  props.setLoaderFlag(true);
                  updateRequestStatus(
                    requestForApproval,
                    true,
                    props.setRequestCount
                  ).then((status) => {
                    props.setLoaderFlag(false);
                    let approvedRequest: boolean[] = status.filter(
                      (state) => state
                    );
                    props.setApprovalMessage(
                      approvedRequest.length + " requests approved!"
                    );
                    props.setApprovalMessagePopup(true);
                    setRequestForApproval([]);
                    setRequestForRejection([]);
                  });
                }
              }}
            >
              Approve
            </div>
            <div
              className="approve-req-icon"
              onClick={() => {
                setButtonState({
                  ...buttonState,
                  approvebtn: !buttonState.approvebtn,
                  rejectbtn: false,
                });
              }}
            >
              <img
                className="approve-req-arrow-icon"
                src={icon_down_arrow}
                alt="down-arrow"
              />
            </div>
          </div>
          {buttonState.approvebtn && (
            <div
              className="approve-dropdown-btn"
              ref={buttonDropDownRef}
              onClick={() => {
                const pendingItems = sortedRequestData.filter(
                  (data: RoleRequestConfigurations) => data.status === "pending"
                );
                if (pendingItems.length > 0) {
                  props.setLoaderFlag(true);
                  updateRequestStatus(
                    pendingItems,
                    true,
                    props.setRequestCount
                  ).then((status) => {
                    props.setLoaderFlag(false);
                    let approvedRequest: boolean[] = status.filter(
                      (state) => state
                    );
                    props.setApprovalMessage(
                      approvedRequest.length + " requests approved!"
                    );
                    props.setApprovalMessagePopup(true);
                    setRequestForApproval([]);
                    setRequestForRejection([]);
                  });
                }
              }}
            >
              Approve All
            </div>
          )}
        </div>
        <div className="reject-btn-cell">
          <div className="reject-btn">
            <div
              className={
                "reject-req-btn " +
                (requestForRejection.length > 0 ? "active-btn" : "")
              }
              onClick={() => {
                props.setLoaderFlag(true);
                if (requestForRejection.length > 0) {
                  updateRequestStatus(
                    requestForRejection,
                    false,
                    props.setRequestCount
                  ).then((status) => {
                    props.setLoaderFlag(false);
                    let approvedRequest: boolean[] = status.filter(
                      (state) => state
                    );
                    props.setApprovalMessage(
                      approvedRequest.length + " requests rejected!"
                    );
                    props.setApprovalMessagePopup(true);
                    setRequestForApproval([]);
                    setRequestForRejection([]);
                    setOnClickEventDetection(!onClickEventDetection);
                  });
                }
              }}
            >
              Reject
            </div>
            <div
              className="reject-req-icon"
              onClick={() => {
                setButtonState({
                  ...buttonState,
                  approvebtn: false,
                  rejectbtn: !buttonState.rejectbtn,
                });
              }}
            >
              <img
                className="reject-req-arrow-icon"
                src={icon_down_arrow_grey}
                alt="down-arrow"
              />
            </div>
          </div>
          {buttonState.rejectbtn && (
            <div
              className="reject-dropdown-btn"
              ref={buttonDropDownRef}
              onClick={() => {
                const pendingItems = sortedRequestData.filter(
                  (data: RoleRequestConfigurations) => data.status === "pending"
                );
                if (pendingItems.length > 0) {
                  props.setLoaderFlag(true);
                  updateRequestStatus(
                    pendingItems,
                    false,
                    props.setRequestCount
                  ).then((status) => {
                    props.setLoaderFlag(false);
                    let approvedRequest: boolean[] = status.filter(
                      (state) => state
                    );
                    props.setApprovalMessage(
                      approvedRequest.length + " requests rejected!"
                    );
                    props.setApprovalMessagePopup(true);
                    setRequestForApproval([]);
                    setRequestForRejection([]);
                    setOnClickEventDetection(!onClickEventDetection);
                  });
                }
              }}
            >
              Reject All
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default AdminRequestTable;
